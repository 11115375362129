import { Routes } from '@angular/router';

import { AuthGuard } from '@celum/authentication';
import { CONFIGURATION_SERVICE, EDIT_MODE, ReadonlyConfigurationService } from '@celum/portals/shared';

import { PortalsAuthGuard } from './portals-auth.guard';
import { portalsCustomDomainAuthGuard } from './portals-custom-domain-auth.guard';
import { userCentricsGuard } from './usercentrics.guard';

export const ROUTES: Routes = [
  {
    path: 'logout',
    loadComponent: () => import('@celum/authentication').then(mod => mod.LogoutComponent)
  },
  {
    path: 'logged-in-proxy',
    // portalsCustomDomainChecker will check if it is a custom domain and redirect to the correct route if needed
    canActivate: [portalsCustomDomainAuthGuard],
    // Property is needed as a minimum to allow component-less routes
    children: []
  },
  {
    path: 'logged-in',
    // AuthGuard will redirect to the final url after login
    canActivate: [AuthGuard],
    // Property is needed as a minimum to allow component-less routes
    children: []
  },
  {
    path: 'not-found',
    loadComponent: () => import('./page-not-found/page-not-found.component')
  },
  {
    path: ':id',
    canActivate: [PortalsAuthGuard, userCentricsGuard],
    loadComponent: () => import('@celum/portals/features/portal').then(mod => mod.PortalComponent),
    providers: [
      { provide: CONFIGURATION_SERVICE, useClass: ReadonlyConfigurationService },
      { provide: EDIT_MODE, useValue: false }
    ]
  },
  {
    path: '',
    redirectTo: 'not-found',
    pathMatch: 'full'
  }
];
