/**
 * Translators are not allowed to have any constructor params!
 */
import { EntityType } from '../model/entity-type';

export abstract class EntityTranslator<ENTITY> {

  public abstract translateToEntity(json: any): ENTITY;

  public abstract translatesToEntityWithName(): string | string[];

  public abstract getEntityType(): EntityType;
}
