import { Directive, HostBinding, Input, OnInit } from '@angular/core';

type ButtonSizes = 'xs' | 'small' | 'medium' | 'large';

@Directive({
             selector: '[celum-button]',
           })
export class CelumButtonDirective implements OnInit {

  @Input() public size: ButtonSizes;

  public readonly sizes: { [key: string]: ButtonSizes } = {
    SMALL: 'small',
    LARGE: 'large',
    MEDIUM: 'medium',
    XS: 'xs'
  };

  @HostBinding('class.celum-btn') public hostClass = true;
  @HostBinding('class.celum-btn--large') public isLarge = false;
  @HostBinding('class.celum-btn--medium') public isMedium = false;
  @HostBinding('class.celum-btn--small') public isSmall = false;
  @HostBinding('class.celum-btn--xs') public isXs = false;

  public ngOnInit(): void {
    this.isLarge = this.size === this.sizes.LARGE;
    this.isMedium = this.size === this.sizes.MEDIUM;
    this.isSmall = this.size === this.sizes.SMALL;
    this.isXs = this.size === this.sizes.XS;
  }

}
