import { DOCUMENT } from '@angular/common';
import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { addScript } from '@celum/core';
import { PortalsProperties } from '@celum/portals/domain';

/**
 * Guard that guarantees the presence of the usercentrics gdpr script if configured.
 * The gdpr prompt should be non-blocking, so the script is loaded asynchronously and the guard always returns true immediately.
 * We choose to ignore error cases, as the script is not critical for the application usage and will be retried on the next page load.
 */
export const userCentricsGuard: CanActivateFn = () => {
  const userCentricConfig = PortalsProperties.properties.usercentrics;
  if (!userCentricConfig?.id) {
    console.log(`Usercentrics: no configuration found, skipping script injection.`);
    return true;
  }

  const { id, scriptSrc } = userCentricConfig;

  const scriptMissing = !inject(DOCUMENT).querySelector(`script[src="${scriptSrc}"]`); // avoid adding the script multiple times
  if (scriptMissing) {
    addScript(scriptSrc, { async: true, attributes: { id: `usercentrics-cmp`, ['data-settings-id']: id } });
  }

  return true;
};
