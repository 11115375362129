import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { CelumIcon } from './components/icon';
import { IconContent } from './components/icon-content';

@NgModule({
            imports: [
              CommonModule, TranslateModule, MatIconModule, HttpClientModule
            ],
            declarations: [
              CelumIcon, IconContent
            ],
            exports: [
              CelumIcon, IconContent, TranslateModule
            ]
          })
export class CelumIconModule {
}
