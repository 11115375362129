// http://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
declare let opr: any;
declare let InstallTrigger: any;
declare let safari: any;

// Opera 8.0+
const isOpera = (!!(window as any).opr && !!opr.addons) || !!(window as any).opera || navigator.userAgent.indexOf(' OPR/') >= 0;

// Firefox 1.0+
const isFirefox = typeof InstallTrigger !== 'undefined';

// Safari 3.0+ "[object HTMLElementConstructor]"
const isSafari = /constructor/i.test((window as any).HTMLElement) || (p => p.toString() === '[object SafariRemoteNotification]')(
                 !(window as any).safari || (typeof safari !== 'undefined' && safari.pushNotification));

const isIOS = !!window.navigator.userAgent.match(/iPad/i) || !!window.navigator.userAgent.match(/iPhone/i);

// Internet Explorer 6-11
const isIE = /*@cc_on!@*/!!(document as any).documentMode;

// Edge 20+
const isEdge = !isIE && !!(window as any).StyleMedia;

// Chrome 1 - 71
const isChrome = !!(window as any).chrome && (!!(window as any).chrome.webstore || !!(window as any).chrome.runtime || /Google Inc/.test(navigator.vendor));

// Blink engine detection
const isBlink = (isChrome || isOpera) && !!(window as any).CSS;

// Android detection
const isAndroid = window.navigator.userAgent.indexOf('Android') > -1;

let desktopScreen: boolean;
let smartphoneScreen: boolean;
let tabletScreen: boolean;

function setScreenVars(): void {
  desktopScreen = window.matchMedia('(min-width: 1025px)').matches;
  smartphoneScreen = window.matchMedia('(max-width: 599px)').matches;
  tabletScreen = !desktopScreen && !smartphoneScreen;
}

window.addEventListener('resize', setScreenVars);
window.matchMedia && setScreenVars();

export const isDesktopScreen = () => desktopScreen;
export const isSmartphoneScreen = () => smartphoneScreen;
export const isTabletScreen = () => tabletScreen;

export class BrowserCheck {

  public static isOpera(): boolean {
    return isOpera;
  }

  // see http://stackoverflow.com/a/4819886
  public static isTouchDevice(): boolean {
    return 'ontouchstart' in window               // works on most browsers
           || navigator.maxTouchPoints > 0;       // works on IE10/11 and Surface
  }

  public static isIE(): boolean {
    return isIE;
  }

  public static isEdge(): boolean {
    return isEdge;
  }

  public static isChrome(): boolean {
    return isChrome;
  }

  public static isFirefox(): boolean {
    return isFirefox;
  }

  public static isSafari(): boolean {
    return isSafari;
  }

  public static isIOS(): boolean {
    return isIOS;
  }

  public static isBlink(): boolean {
    return isBlink;
  }

  public static isAndroid(): boolean {
    return isAndroid;
  }

  // this has room for improvement...
  public static getBrowserInfo(): string {
    const device: string = this.isTouchDevice() ? ' (touch)' : ' (desktop)';
    if (isIE) {
      return 'IE' + device;
    }
    if (isEdge) {
      return 'Edge' + device;
    }
    if (isChrome) {
      return 'Chrome' + device;
    }
    if (isFirefox) {
      return 'Firefox' + device;
    }
    if (isSafari) {
      return 'Safari' + device;
    }
    if (isIOS) {
      return 'IOS' + device;
    }
    if (isOpera) {
      return 'Opera' + device;
    }
    if (isAndroid) {
      return 'Android' + device;
    }
    return 'Unknown Browser' + device;
  }

  public static getOsInfo(): 'MacOS' | 'iOS' | 'Windows' | 'Android' | 'Linux' {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

    if (platform.toLocaleLowerCase().startsWith('mac')) {
      return 'MacOS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      return 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      return 'Windows';
    } else if (/Android/.test(userAgent)) {
      return 'Android';
    } else if (/Linux/.test(platform)) {
      return 'Linux';
    }

    return null;
  }
}
