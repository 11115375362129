import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { catchError, map, Observable, of, switchMap, take, tap } from 'rxjs';

import { AuthGuard, AuthService, LoginRedirectService, TenantService } from '@celum/authentication';
import { ConfigurationResolver } from '@celum/portals/shared';

@Injectable()
export class PortalsAuthGuard extends AuthGuard {
  constructor(
    private portalConfigResolver: ConfigurationResolver,
    private tenantService: TenantService,
    authService: AuthService,
    router: Router,
    loginRedirectService: LoginRedirectService
  ) {
    super(authService, router, loginRedirectService);
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // Try to load the portal configuration. Protected portals will throw a 403 and return the tenantId, which is a signal to start authentication
    return this.portalConfigResolver.configuration$.pipe(
      take(1),
      switchMap(config => (!config ? this.portalConfigResolver.resolve(route) : of(config))),
      catchError(error => {
        if (error?.status === 403 && error?.error?.orgId) {
          // This specific error contains the tenantId - store if for this session so the followup requests can be authenticated
          this.tenantService.storeTenant(error.error.orgId);
          // Call the canActivate method to make sure we are logged in/handling the logged-in route
          return super.canActivate(route, state).pipe(
            // Handle one extra case: When a logged in user visits a different protected portal for the first time, we need to reload after setting the tenantId
            tap(() => this.router.navigateByUrl(state.url))
          );
        }
        return of(false);
      }),
      map(canActivate => !!canActivate)
    );
  }
}
