export class BrowserUtil {
  /**
   * Opens the given url. The url can be opened in the current tab, the _blank target or a specific, named target.
   *
   * @param url the url to be opened
   * @param blankOrTarget a new tab or a specific target
   */
  public static openUrl(url: string, blankOrTarget: string | boolean = false): void {
    if (typeof blankOrTarget === 'string') {
      window.open(url, blankOrTarget);
    } else if (blankOrTarget) {
      window.open(url, '_blank');
    } else {
      window.location.href = url;
    }
  }

  /**
   * Uses the performance navigation api to detect reload/refresh vs initial load/deep linking
   * @TECHDEBT replace with PerformanceNavigationTiming API as soon as it not experimental anymore
   */
  public static isReload(): boolean {
    const performanceNavigation: PerformanceNavigation = performance.navigation;
    const performanceNavigationType: number = performanceNavigation.type;
    return PerformanceNavigation.TYPE_RELOAD === performanceNavigationType;
  }
}
