import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';

import { ReactiveService } from '@celum/core';

import { DateUtil } from '../util/date/date-util';
import { DateService } from '../util/date.service';

@Pipe({
        name: 'formatDate',
        pure: true
      })
export class FormatDatePipe extends ReactiveService implements PipeTransform, OnDestroy {

  constructor(protected translate: TranslateService, private changeDetector: ChangeDetectorRef) {
    super();
    this.translate.onLangChange.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.changeDetector.markForCheck());
  }

  public transform(date: string | number | Date, currentLocale?: string, showTime?: boolean, format: string = DateUtil.FORMATS.DATE.NUMERIC): string {
    if (!date) {
      return '';
    }
    return DateService.formatDateForGivenLocale(
      typeof date === 'number' ? date : (typeof date === 'string' ? new Date(date) : date).getTime(),
      currentLocale,
      format,
      showTime
    );
  }

  public ngOnDestroy(): void {
    super.destroy();
  }
}
