/**
 * Utility function to easily set a css variable
 * @param name of the variable
 * @param value which should be assigned
 * @param element optional - html element on which the variable will be defined, defaults to document
 */
export function setCssVariable(name: string, value: any, element?: HTMLElement): void {
  const targetElement = element || document.documentElement;
  if (targetElement && name) {
    const sanitizedName = name.startsWith('--') ? name : name.startsWith('-') ? `-${name}` : `--${name}`;
    targetElement.style.setProperty(sanitizedName, value);
  }
}
