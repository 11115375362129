import { Context } from '../../../core/common/context';

export class OperationContext {

  /**
   * Full set of available operations for an EntityType, eg. for operations page
   */
  public static FULL: Context = Context.ofLevel('full', 10);

  /**
   * Full set of available operations for an EntityType
   */
  public static LIMITED: Context = Context.ofLevel('limited', 20);

  /**
   * Basic operation for an EntityType
   */
  public static BASE: Context = Context.ofLevel('base', 30);

  /**
   * General operations, available on each page (eg. featured actions of magic button)
   */
  public static GENERAL: Context = Context.ofLevel('general', 40);
}
