import { createFeatureSelector, createSelector, createSelectorFactory, resultMemoize } from '@ngrx/store';

import { GraphRelation, isArrayEqual } from '@celum/core';

export class RelationsListState {
  public relationLists: { [key: string]: RelationList } = {}; // key: relationId (source or target specific)
  public queryForRelationList: { [key: string]: Set<string> } = {}; // key: queryId, value: list of relationList ids
  public relations: { [key: string]: GraphRelation } = {};
}

export interface RelationList {
  id: string;
  relationType: string;
  queryIds: string[];
  relationIds: string[];

  current: string[];
  previous: string[];
}

export const selectRelationsState = createFeatureSelector<RelationsListState>('relationList');

export const selectRelationListById = createSelector(
  selectRelationsState,
  (state: RelationsListState, props: { id: string }) => state.relationLists[props.id]
);

const simpleArrayMemoize = (fn: () => any) => resultMemoize(fn, isArrayEqual);

/**
 * Factory function to create a selector which is returning entities by ids.
 * Memoization function was overwritten to only emit if entities changed for real (not if only array-reference changes, which would be every time)
 * Use factory function to avoid unnecessary re-evaluation https://ngrx.io/guide/store/selectors#using-selectors-with-props
 */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const selectRelationsByIds = <T extends GraphRelation>(ids: string[]) =>
  createSelectorFactory<any, T[]>(simpleArrayMemoize)(
    selectRelationsState,
    (state: RelationsListState) => (ids || []).map(id => state.relations[id]).filter(relation => !!relation)
  );
