/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createFeatureSelector, createSelector, createSelectorFactory, MemoizedSelector, resultMemoize } from '@ngrx/store';

import { GraphEntity, isArrayEqual, LastLoaded } from '@celum/core';

export class EntitiesState {
  public entitiesByType: { [key: string]: Set<string> } = {};
  public entitiesById: { [key: string]: GraphEntity } = {};
  public lastLoaded: { [key: string]: LastLoaded } = {};
}

export const selectEntitiesState = createFeatureSelector<EntitiesState>('entities');

export const getLastLoadedInfo = (id: string) => createSelector<any, [any], LastLoaded>(
  selectEntitiesState,
  state => state.lastLoaded[id]
);

export const getEntitiesById = createSelector(
  selectEntitiesState,
  state => state.entitiesById
);

export const getEntitiesByType = createSelector(
  selectEntitiesState,
  state => state.entitiesByType
);

export const entityByTypeFn = <T extends GraphEntity>(state: EntitiesState, typeKey: string): T[] => {
  const entityIds = state.entitiesByType[typeKey] || new Set();
  return Array.from(entityIds).map(id => state.entitiesById[id]) as T[];
};

export const getEntitiesBySpecificType = <T extends GraphEntity>(typeKey: string) => createSelector<any, [any], T[]>(
  selectEntitiesState,
  (state: EntitiesState) => entityByTypeFn<T>(state, typeKey)
);

/**
 * Factory function to create a selector which is returning an entity by id.
 * Could be a simple selector but as factory - we can add generics to type return values.
 */
export const getEntityById = <T extends GraphEntity>(id: string) => createSelector<any, [any], T>(
  selectEntitiesState,
  (state: EntitiesState) => state.entitiesById[id] as T
);

const simpleArrayMemoize = (fn: () => any) => resultMemoize(fn, isArrayEqual);

/**
 * Factory function to create a selector which is returning entities by ids.
 * Memoization function was overwritten to only emit if entities changed for real (not if only array-reference changes, which would be every time)
 * Use factory function to avoid unnecessary re-evaluation https://ngrx.io/guide/store/selectors#using-selectors-with-props
 */
export const getEntitiesByIds = <T extends GraphEntity>(ids: string[]) =>
  createSelectorFactory<any, T[]>(simpleArrayMemoize)(
    selectEntitiesState,
    (state: EntitiesState) => mapIdsToEntities(ids, state)
  );

/**
 * Function that takes a selector which returns a string array of ids and maps them to do the same thing as getEntitiesByIds.
 * This enables us to create a selector for ids using another selector as an input without having to care about memoization, etc.
 *
 * e.g.
 * const idsSelector = createSelector(state, state.ids);
 * const entitySelector = getEntitiesByIdSelector(idsSelector);
 *
 * @param selector which provides the ids
 */
export const getEntitiesByIdSelector = <T extends GraphEntity>(selector: MemoizedSelector<object, string[]>) => {
  return createSelectorFactory<any, T[]>(simpleArrayMemoize)(
    selector,
    selectEntitiesState,
    mapIdsToEntities
  );
};

function mapIdsToEntities<T extends GraphEntity>(ids: string[], state: EntitiesState): T[] {
  return (ids || []).map(id => state.entitiesById[id]).filter(entity => !!entity) as T[];
}
