import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { combineLatest, filter, map, Observable, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';

import { CelumPropertiesProvider, DataUtil, isTruthy } from '@celum/core';

import { LicenseService } from './license.service';
import { TenantService } from './tenant.service';
import { License, LicenseDto, LicenseType } from '../model/license.model';
import { AccountAccess, AccountAccessStatus, ExperiencePrivilege, UserDetails } from '../model/user.model';

export interface UserServiceState {
  currentUser?: UserDetails;
}

@Injectable()
export class UserService extends ComponentStore<UserServiceState> {
  public currentUser$ = this.select(state => state.currentUser);
  public activeAccountAccesses$ = this.currentUser$.pipe(
    isTruthy(),
    map(user => user.accountAccesses.filter(accountAccess => accountAccess.status === AccountAccessStatus.ACTIVE))
  );

  /**
   * Returns the account access for the current tenant. The first value is emitted when the current tenant is loaded.
   */
  public accountAccessForCurrentTenant$: Observable<AccountAccess> = combineLatest([this.activeAccountAccesses$, this.tenantService.currentTenantId$]).pipe(
    map(([accountAccesses, tenantId]) => accountAccesses.find(accountAccess => accountAccess.accountId === tenantId)),
    isTruthy()
  );

  /** Returns the current user and the current tenant. */
  public userInfo$ = combineLatest([this.currentUser$, this.accountAccessForCurrentTenant$]).pipe(
    map(([user, tenant]) => ({
      user,
      tenant
    }))
  );

  constructor(
    private http: HttpClient,
    private tenantService: TenantService,
    private licenseService: LicenseService,
    private location: Location,
    private router: Router
  ) {
    super({});
  }

  public getCurrentUser(): UserDetails {
    return this.get().currentUser;
  }

  public getAccountAccessForCurrentTenant(): AccountAccess {
    return this.getCurrentUser().accountAccesses.find(
      accountAccess => accountAccess.accountId === this.tenantService.getCurrentTenantId() && accountAccess.status === AccountAccessStatus.ACTIVE
    );
  }

  public loadCurrentUser(): Observable<void> {
    // Note: Do NOT catch an error here - this is called on several places, and depending on where it is called the error needs to be handled differently
    return this.http.get<UserDetails>(`${CelumPropertiesProvider.properties.authentication.saccUrl}/users/current`).pipe(
      tap(user => this.patchState({ currentUser: this.mapUser(user) })),
      switchMap(() => this.storeTenantIfUnset())
    );
  }

  public storeTenantIfUnset(): Observable<void> {
    const license = this.licenseService.applicationLicense;
    if (!license) {
      return of(void 0);
    }
    if (!this.tenantService.getCurrentTenantId() && !this.tenantService.getTenantIdFromUrl()) {
      return this.activeAccountAccesses$.pipe(
        map(accounts => accounts.filter(account => UserService.getValidLicenseByType(account, license))),
        map(accounts => accounts.sort((account1, account2) => account1.accountName.localeCompare(account2.accountName))),
        take(1),
        tap(accounts => {
          if (accounts.length > 0) {
            const tenantId = accounts[0].accountId;
            // Store the first item in the list from the BE
            this.tenantService.storeTenant(tenantId);
            // This is only needed right after login: Because the mechanism that uses APP_BASE_HREF to add the tenantId to the url has already run at application
            // startup, we have to update the url with the tenantId WITHOUT actually navigating somewhere. This will stay active until a refresh to apply this when
            // the user e.g. navigates to a subpage
            this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
              const finalDestination = (event as NavigationEnd).urlAfterRedirects;
              // Only add the tenantId if the app somehow is not able to add it by itself
              !window.location.href.includes('/tenant/') && this.location.replaceState(`/tenant/${tenantId}${finalDestination}`);
            });
          }
        }),
        map(() => void 0)
      );
    }

    return of(void 0);
  }

  public changeLocale(locale: string): Observable<UserDetails> {
    return this.http
      .put<UserDetails>(`${CelumPropertiesProvider.properties.authentication.saccUrl}/users/current`, {
        locale
      })
      .pipe(
        tap(user => {
          this.patchState({ currentUser: this.mapUser(user) });
        })
      );
  }

  private mapUser(response: UserDetails): UserDetails {
    return {
      admin: response.admin,
      invitations: response.invitations,
      trialPlan: response.trialPlan,
      oid: response.oid,
      status: response.status,
      firstName: response.firstName,
      lastName: response.lastName,
      email: response.email,
      locale: response.locale,
      profilePictureDownloadLink: response.profilePictureDownloadLink,
      provisioningType: response.provisioningType,
      accountAccesses: response?.accountAccesses.map((accountAccess: AccountAccess) => ({
        accountId: accountAccess.accountId,
        accountName: accountAccess.accountName,
        accountAccessToken: accountAccess.accountAccessToken,
        ownerOid: accountAccess.ownerOid,
        ownerName: accountAccess.ownerName,
        ownerEmail: accountAccess.ownerEmail,
        profilePictureDownloadLink: accountAccess.profilePictureDownloadLink,
        storageLimit: accountAccess.storageLimit,
        status: accountAccess.status,
        role: accountAccess.role,
        accountLogoDownloadLink: accountAccess.accountLogoDownloadLink,
        discriminator: accountAccess.discriminator,
        licenses: accountAccess.licenses.map((license: LicenseDto) => UserService.mapLicense(license)),
        privileges: accountAccess.privileges,
        experiencePermissions: accountAccess.experiencePermissions
      }))
    };
  }

  public static getValidLicenseByType(accountAccess: AccountAccess, licenseType: LicenseType): License {
    if (
      accountAccess.status !== AccountAccessStatus.ACTIVE ||
      licenseType === LicenseType.NO_LICENSE ||
      !UserService.hasValidAccountAccessPrivilege(accountAccess, licenseType)
    ) {
      return undefined;
    }

    return accountAccess.licenses.filter(license => license.active).find(license => license.type === licenseType);
  }

  public static hasValidAccountAccessPrivilege(accountAccess: AccountAccess, licenseType: LicenseType): boolean {
    /* 2 notes here:
     * Currently we check here whether the user has the privilege to use libraries/experience by checking the experiencePERMISSIONS field - this is a work in
     progress, and will be redone in the future
     * I went with a switch-case here, because with an if-statement IntelliJ was complaining about not being able to compare the licenseTypes because there was
     no overlap?
     */
    switch (licenseType) {
      case LicenseType.LIBRARIES:
      case LicenseType.EXPERIENCE:
        return accountAccess.privileges
          ? accountAccess.privileges.experience === ExperiencePrivilege.FULL_ACCESS
          : !DataUtil.isEmpty(accountAccess.experiencePermissions);
      default:
        return true;
    }
  }

  private static mapLicense(license: LicenseDto): License {
    switch (license.type) {
      case LicenseType.WORK:
        return {
          type: LicenseType.WORK,
          active: license.active,
          creationLimit: license.creationLimit,
          storageLimit: license.storageLimit,
          wrUrl: license.wrUrl,
          wrTenantAwareUrl: license.wrTenantAwareUrl
        };
      case LicenseType.EXPERIENCE:
      case LicenseType.LIBRARIES:
        return {
          type: LicenseType.EXPERIENCE,
          active: license.active,
          experienceUrl: license.experienceUrl,
          experienceTenantAwareUrl: license.experienceTenantAwareUrl,
          librariesUrl: license.librariesUrl,
          librariesTenantAwareUrl: license.librariesTenantAwareUrl
        };
      case LicenseType.DRIVE: {
        return {
          type: LicenseType.DRIVE,
          active: license.active,
          edition: license.edition
        };
      }
      case LicenseType.X3D:
        return {
          type: LicenseType.X3D,
          active: license.active,
          monthlyUploadLimit: license.monthlyUploadLimit
        };
      case LicenseType.CONTENT_HUB:
        return {
          type: LicenseType.CONTENT_HUB,
          active: license.active,
          chRepositories: license.chRepositories?.map(chRepository => ({
            repositoryId: chRepository.repositoryId,
            url: chRepository.url
          }))
        };
      case LicenseType.XSIM_SEARCH:
        return {
          type: LicenseType.XSIM_SEARCH,
          active: license.active,
          monthlyRequestLimit: license.monthlyRequestLimit
        };
      default:
        console.warn(`UserService: Unknown license type: ${license.type}`);
        return {
          type: LicenseType.NO_LICENSE,
          active: false
        };
    }
  }
}
