import { ChangeDetectorRef, ComponentRef, Directive, Input, OnChanges, OnInit, SimpleChanges, Type, ViewContainerRef } from '@angular/core';

/**
 * This directive loads a component dynamically and renders it on the view container it is provided on.
 * The main use case is to bundle individual components into their own chunk to not interfere with loading times of other components on the same page.
 *
 * Usage:
 *
 * <ng-template [dynamicComponent]="loadDynamicComponent" [inputs]="{ input1: 2, input2: 'hello' }"></ng-template>
 * public loadDynamicComponent() {
 *   return import(`./path-to-component`).then(module => module.Component);
 * }
 *
 * Things to consider
 * - components rendered by this directive are not styleable from within an encapsulated parent component
 * - inputs must be passed as an object through the "inputs" input, output propagation is not yet supported
 * - the "dynamicComponent" load function has to be provided in a way that it is accessible in ngOnInit
 *
 */
@Directive({
  selector: '[dynamicComponent]',
  standalone: true
})
export class DynamicComponentDirective<C> implements OnChanges, OnInit {
  /** Function which returns a promise of the component type that should be rendered. You will most likely want to use dynamic imports to get lazy loading. */
  @Input() public dynamicComponent: () => Promise<Type<C>>;

  /**
   * Pass inputs to the dynamically created component in form of a partial object.
   * Every key-value pair of the partial will be written onto the component instance if the value changed (reference check).
   * Inputs that are not set on the partial will be ignored. To clear an input on the component instance, specify it in the object like { input: null/undefined }
   */
  @Input() public inputs: Partial<C>;

  private componentRef: ComponentRef<C>;

  constructor(private viewContainerRef: ViewContainerRef, private changeDetector: ChangeDetectorRef) {}

  public ngOnInit(): void {
    if (!this.dynamicComponent) {
      console.error(`DynamicComponentDirective: The "dynamicComponent" load function was not provided initially. It needs to be accessible in ngOnInit.`);
      return;
    }

    this.dynamicComponent().then(component => {
      this.componentRef = this.viewContainerRef.createComponent(component);
      this.setInputs();
      this.changeDetector.detectChanges(); // not sure why this is needed but it is
    });
  }

  public ngOnChanges({ inputs }: SimpleChanges): void {
    inputs && this.setInputs();
  }

  private setInputs(): void {
    if (!this.componentRef || !this.inputs) {
      return;
    }

    Object.entries(this.inputs)
      .filter(([key, value]) => this.componentRef.instance[key as keyof C] !== value)
      .forEach(([key, value]) => this.componentRef.setInput(key, value));
  }
}
