import { MsalGuardConfiguration } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';

import { CelumPropertiesProvider } from '@celum/core';

export function MSALInstanceFactory(logLevel: LogLevel = LogLevel.Info): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: CelumPropertiesProvider.properties.authentication.clientId,
      authority: `https://${CelumPropertiesProvider.properties.authentication.b2cDomain}/${CelumPropertiesProvider.properties.authentication.b2cTenantId}/${CelumPropertiesProvider.properties.authentication.defaultSigninPolicy}`,
      redirectUri: CelumPropertiesProvider.properties.authentication.redirectUri,
      navigateToLoginRequestUrl: false,
      postLogoutRedirectUri: CelumPropertiesProvider.properties.authentication.logoutRedirectUri,
      knownAuthorities: [CelumPropertiesProvider.properties.authentication.b2cDomain]
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage
    },
    system: {
      allowRedirectInIframe: true,
      loggerOptions: {
        loggerCallback: (level: LogLevel, message: string) => {
          switch (level) {
            case LogLevel.Trace:
              console.trace(message);
              break;
            case LogLevel.Verbose:
              console.debug(message);
              break;
            case LogLevel.Warning:
              console.warn(message);
              break;
            case LogLevel.Error:
              console.error(message);
              break;
            default:
              console.log(message);
              break;
          }
        },
        logLevel,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: []
    }
  };
}
