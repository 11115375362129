import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { CustomDomainAuthFlowState } from '@celum/authentication';
import { CustomDomainCheckService } from '@celum/portals/shared';

const PORTALS_CUSTOM_DOMAIN_AUTH_GUARD = 'portalsCustomDomainAuthGuard';

/**
 * This guard is used to check if the redirectUriAfterLogin is a custom domain.
 * In this case, the domain is run through a CNAME check. If the check is successful, the user is redirected to the custom domain.
 * If the check fails, the user is redirected to a not-found page.
 */
export const portalsCustomDomainAuthGuard: CanActivateFn = route => {
  console.debug(`${PORTALS_CUSTOM_DOMAIN_AUTH_GUARD}: Custom domain guard activated. Checking if the redirectUriAfterLogin is a custom domain.`);

  const stateParam = new URLSearchParams(route.fragment).get('state');

  // If there is no state parameter, or it doesn't contain a redirectUriAfterLogin, there is no need to check the custom domain.
  if (!stateParam) {
    console.debug(`${PORTALS_CUSTOM_DOMAIN_AUTH_GUARD}: No state parameter found in the url fragment.`);
    return true;
  }

  const stateObject = parseStateObject(stateParam);
  console.debug(`${PORTALS_CUSTOM_DOMAIN_AUTH_GUARD}: Parsed state object:`, stateObject);

  if (!stateObject?.redirectUriAfterLogin) {
    console.debug(`${PORTALS_CUSTOM_DOMAIN_AUTH_GUARD}: No redirectUriAfterLogin found in the state parameter.`);
    return true;
  }

  const customRedirectUri = new URL(stateObject.redirectUriAfterLogin);

  const portalIdOrSlug = new URL(stateObject.originatingWindowLocationHref).pathname.split('/')[1];

  console.debug(`${PORTALS_CUSTOM_DOMAIN_AUTH_GUARD}: CNAME check for ${customRedirectUri.host} initiated.`);
  return inject(CustomDomainCheckService)
    .cNameCheck(customRedirectUri.host, portalIdOrSlug)
    .then(() => {
      // If the CNAME check is successful, the user is redirected to the custom domain with the authentication fragment.
      console.debug(`${PORTALS_CUSTOM_DOMAIN_AUTH_GUARD}: CNAME check for ${customRedirectUri.host} succeeded. Redirecting to custom domain.`);
      window.location.href = `${stateObject.redirectUriAfterLogin}#${route.fragment}`;
      return false;
    })
    .catch(() => {
      // If the CNAME check fails, the user is redirected to a not-found page.
      console.debug(`${PORTALS_CUSTOM_DOMAIN_AUTH_GUARD}: CNAME check for ${customRedirectUri.host} failed.`);
      window.location.href = `${customRedirectUri.origin}/not-found`;
      return false;
    });
};

function parseStateObject(state: string): CustomDomainAuthFlowState {
  const splitState = decodeURIComponent(state).split('|');
  if (splitState.length <= 1) {
    console.debug(`${PORTALS_CUSTOM_DOMAIN_AUTH_GUARD}: Invalid state parameter found in the url fragment.`);
    return null;
  }

  return JSON.parse(atob(splitState[1]));
}
