import { CelumProperties } from './celum-properties.model';

/**
 * When constructed, it checks the local storage, to override some {@link CelumProperties} according to state after application is initialized
 */
export class InjectedCelumProperties implements CelumProperties {
  public autoUpdateQueries: boolean;
  public availableLanguages: string[];
  public baseAddress: string;
  public httpBaseAddress: string;
  public buildTimestamp: string;
  public defaultLanguage: string;
  public helpLinks: { [key: string]: string };
  public liveUpdateInterval: number;
  public logCommunication: boolean;
  public logLevel: number;
  public consoleLogLevel: number;
  public logPerformanceMeasurements: boolean;
  public logSendMessages: boolean;
  public ng2ProdMode: boolean;
  public privacyPolicyLink: string;
  public termsConditionsLink: string;
  public sharingHttpBaseAddress: string;
  public librariesHttpBaseAddress: string;
  public librariesUrl: string;
  public experienceUrl: string;
  public supportPortalUrl: string;
  public experienceHttpBaseAddress: string;
  public stackTraceLines: number;
  public translationPath: string;
  public tutorialVideoLink: string;
  public queryRetryDelay: number;
  public queryRetries: number;
  public queryRetryForConnectionLossOnly: boolean;
  public pingInterval: number;
  public pingAddress: string;
  public uiLogsReportInterval: number;
  public redirectToUrlOn401: string;
  public redirectToSelfOn403: boolean;
  public maxConcurrentUploads: number;
  public connectionCheck: boolean;
  public appProperties: { [key: string]: any };
  public authentication: {
    clientId: string;
    b2cDomain: string;
    b2cTenantId: string;
    aDDomain: string;
    redirectUri: string;
    redirectTokenUri: string;
    logoutRedirectUri: string;
    defaultSigninPolicy: string;
    saccUrl: string;
    saccAccountMemberUrl: string;
    applicationLicenseType: string;
    applicationFallbackPagesPerLanguage: { [language: string]: string };
  };
  public applicationInsights: {
    instrumentationKey: string;
    endpointUrl: string;
    samplingPercentage: number;
    enableAutoRouteTracking: boolean;
  };
  public applicationName: string;
  public systemBar: {
    contentHubFallbackUrlPerLanguage: { [language: string]: string };
    librariesFallbackUrlPerLanguage: { [language: string]: string };
    experienceFallbackUrlPerLanguage: { [language: string]: string };
    workAppUrl: string;
    librariesAppUrl: string;
    experienceAppUrl: string;
    helpUrls: {
      helpCenter: { [language: string]: string };
      termsConditions: { [language: string]: string };
      privacyPolicy: { [language: string]: string };
    };
  };
  public userPilot?: {
    apiKey: string;
  };
  public versionInformation?: {
    version?: string;
  };

  constructor(celumProperties: CelumProperties) {
    this.availableLanguages = celumProperties.availableLanguages;
    this.httpBaseAddress = celumProperties.httpBaseAddress;
    this.buildTimestamp = celumProperties.buildTimestamp;
    this.defaultLanguage = celumProperties.defaultLanguage;
    this.helpLinks = celumProperties.helpLinks;
    this.logLevel = celumProperties.logLevel;
    this.consoleLogLevel = celumProperties.consoleLogLevel;
    this.ng2ProdMode = celumProperties.ng2ProdMode;
    this.privacyPolicyLink = celumProperties.privacyPolicyLink;
    this.sharingHttpBaseAddress = celumProperties.sharingHttpBaseAddress;
    this.librariesHttpBaseAddress = celumProperties.librariesHttpBaseAddress;
    this.librariesUrl = celumProperties.librariesUrl;
    this.experienceUrl = celumProperties.experienceUrl;
    this.supportPortalUrl = celumProperties.supportPortalUrl;
    this.stackTraceLines = celumProperties.stackTraceLines;
    this.termsConditionsLink = celumProperties.termsConditionsLink;
    this.translationPath = celumProperties.translationPath;
    this.tutorialVideoLink = celumProperties.tutorialVideoLink;
    this.queryRetryDelay = celumProperties.queryRetryDelay;
    this.queryRetries = celumProperties.queryRetries;
    this.queryRetryForConnectionLossOnly = celumProperties.queryRetryForConnectionLossOnly;
    this.pingInterval = celumProperties.pingInterval;
    this.pingAddress = celumProperties.pingAddress;
    this.uiLogsReportInterval = celumProperties.uiLogsReportInterval;
    this.redirectToUrlOn401 = celumProperties.redirectToUrlOn401;
    this.redirectToSelfOn403 = celumProperties.redirectToSelfOn403;
    this.maxConcurrentUploads = celumProperties.maxConcurrentUploads;
    this.appProperties = celumProperties.appProperties;
    this.connectionCheck = celumProperties.connectionCheck;
    this.authentication = celumProperties.authentication;
    this.applicationInsights = celumProperties.applicationInsights;
    this.applicationName = celumProperties.applicationName;
    this.systemBar = celumProperties.systemBar;
    this.userPilot = celumProperties.userPilot;
    this.versionInformation = celumProperties.versionInformation;

    const consoleMsgObject: any = {
      autoUpdateQueries: this.autoUpdateQueries,
      liveUpdateInterval: this.liveUpdateInterval,
      logCommunication: this.logCommunication,
      consoleLogLevel: this.consoleLogLevel,
      logPerformanceMeasurements: this.logPerformanceMeasurements,
      logSendMessages: this.logSendMessages,
      stackTraceLines: this.stackTraceLines,
      queryRetryDelay: this.queryRetryDelay,
      queryRetries: this.queryRetries
    };

    if (this.consoleLogLevel <= 1) {
      console.debug('CelumProperties: Injected by InjectedCelumProperties: ' + JSON.stringify(consoleMsgObject, null, '  '));
    }
  }
}
