// @dynamic
export class UUIDGenerator {

  // copied from http://stackoverflow.com/a/2117523
  // since we do not rely that heavily on this to be absolutely unique, it should be sufficient to use Math.random()
  public static generateId(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      const r = Math.random() * 16 | 0;
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}
