/**
 * Sort all keys and values of an object recursively.
 */
export function sortObject<T>(obj: T): T {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    const sortedArray = obj.map(sortObject);
    return sortedArray.sort(sortArrays) as T;
  }

  return sortObjectKeys(obj);
}

function sortObjectKeys<T extends object>(obj: T): T {
  const sortedObj: any = {};

  Object.keys(obj)
        .sort()
        .forEach(key => (sortedObj[key] = sortObject(obj[key as keyof T])));

  return sortedObj as T;
}

function sortArrays<T>(a: T[], b: T[]): number {
  if (typeof a !== 'object' || a === null || typeof b !== 'object' || b === null) {
    return a === b ? 0 : a < b ? -1 : 1;
  }
  const aJson = JSON.stringify(sortObject(a));
  const bJson = JSON.stringify(sortObject(b));
  return aJson.localeCompare(bJson);
}
