import { TenantService } from './services/tenant.service';

/**
 * This will always add a 'tenant/{tenantId}' part between origin and subpaths without affecting routing
 */
export function tenantHrefFactory(tenantService: TenantService): string {
  // IMPORTANT: In case this needs to be reworked/moved: There is a counterpart in the user.service in the method 'storeTenantIfUnset' that does the same manually
  // in the case directly after login (since this code here is executed directly at the startup of the application, we will not have a tenantId directly after
  // login, so that use case is covered in the user.service)
  const urlTenantId = tenantService.getTenantIdFromUrl();
  if (urlTenantId) {
    tenantService.storeTenant(urlTenantId);
  }
  const resolvedTenantId = urlTenantId || tenantService.getCurrentTenantId();
  return resolvedTenantId ? `/tenant/${resolvedTenantId}` : '';
}
