import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

/**
 * This directive watches its element and emits if the visibility status in the viewport changes.
 *
 * Simple usage: emit if a part of the element comes into or out of the viewport
 * <div inViewport (visible)="handleChange($event)"></div>
 *
 * With options: emit if at least 50% of the element comes into or out of the viewport
 * <div inViewport [inViewportObserverProps]="{ threshold: 0.5 }" (visible)="handleChange($event)"></div>
 *
 */
@Directive({
  standalone: true,
  selector: '[inViewport]'
})
export class InViewportDirective implements OnInit, OnDestroy {
  /* specify properties for the intersection observer, see https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver#instance_properties **/
  @Input() public inViewportObserverProps: IntersectionObserverInit;

  @Output() public readonly visible = new EventEmitter<boolean>();

  private observer: IntersectionObserver;

  constructor(private element: ElementRef) {}

  public ngOnInit(): void {
    this.element && this.registerObserver();
  }

  public ngOnDestroy(): void {
    this.observer && this.observer.disconnect();
  }

  private registerObserver(): void {
    this.observer = new IntersectionObserver(entries => {
      // we call observe once per observer so the entries will always be from the same element - therefore we are only interested in the most recent one (last)
      const entry = entries[entries.length - 1];
      this.visible.emit(entry.isIntersecting);
    }, this.inViewportObserverProps);

    this.observer.observe(this.element.nativeElement);
  }
}
