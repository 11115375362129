import { Pipe, PipeTransform } from '@angular/core';

import { DomHelper } from '../util/dom-helper';

@Pipe({
        name: 'escapeHtml',
        pure: true
      })
/**
 * Escapes html tags in the passed string.
 *
 * By default, only tags around embedded media elements, such as iframe, video & audio are escaped. Optionally, all html tags can be escaped.
 *
 * This pipe doesn't necessarily remove all security relevant elements, use {@link DomSanitizer} for that.
 */
export class EscapeHtmlPipe implements PipeTransform {

  private EMBEDDED_TAGS: string [] = ['iframe', 'video', 'audio'];

  public transform(text: string, escapeOnlyEmbeddedTags = true): string {
    return escapeOnlyEmbeddedTags ? EscapeHtmlPipe.checkTagsForHtmlEscaping(text, this.EMBEDDED_TAGS) : DomHelper.escapeHtml(text, false);
  }

  private static checkTagsForHtmlEscaping(text: string, tags: string  []): string {
    if (text && tags.some(v => text.includes(v))) {
      return DomHelper.escapeHtml(text, false);
    }
    return text;
  }

}
