import { Inject, Injectable, InjectionToken } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, EventType, RedirectRequest } from '@azure/msal-browser';
import { AuthenticationResult } from '@azure/msal-common';
import { BaseAuthRequest } from '@azure/msal-common/dist/request/BaseAuthRequest';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, concat, distinctUntilChanged, EMPTY, EmptyError, filter, first, Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

import { CelumPropertiesProvider, DataUtil, isTruthy } from '@celum/core';

import { LoginRedirectService } from '../services/login-redirect.service';
import { TenantService } from '../services/tenant.service';
import { UserService } from '../services/user.service';
import { tokenProviders } from '../token-providers';
import { isCustomDomainRedirect } from '../utils/custom-domain.util';

export type CustomDomainAuthFlowState = {
  originatingWindowLocationHref: string;
  redirectUriAfterLogin: string;
};

export class UserInfoLoadingError extends Error {
  constructor(message?: string, options?: ErrorOptions) {
    super(message, options);
    this.name = 'UserInfoError';
  }
}

export interface AuthServiceConfig {
  /**
   * Whether the authentication library should load the current user after a login. Setting this to false means the application is responsible to load the
   * current user if needed.
   */
  loadSaccUserAfterSignIn: boolean;
}

export const AUTH_SERVICE_CONFIG = new InjectionToken<AuthServiceConfig>('Auth Service Config', {
  providedIn: 'root',
  factory: () => ({ loadSaccUserAfterSignIn: true })
});

@Injectable()
export class AuthService {
  private static readonly NEW_USER_CLAIM = 'newUser';
  private static readonly TFP_CLAIM = 'tfp';

  public account$: Observable<{ email: string; newUser: boolean }>;
  public msalRedirectResponse$: BehaviorSubject<AuthenticationResult> = new BehaviorSubject<AuthenticationResult>(undefined);

  /**
   * Function that gets called before the signout process
   * gets executed. Can be used to make some cleanup before the actual
   * signout.
   */
  public onSignOut: () => void;
  private internalIsAuthenticated$ = new ReplaySubject<boolean>(1);

  /**
   * List of specific errors we have to handle during login
   */
  private readonly loginErrorCodes = [
    'AADB2C', // Generic login error
    'AADB2C90273', // Special error thrown when e.g. cancelling the "consent to MS using your email address"-screen
    'AADB2C90091' // User cancels registration/creation
  ];

  /**
   * Fixed permission scope that needs to be configured in the application permissions in AD. Without at least one scope, we get no access token in the B2C
   * token returned, and consequently MSAL will not be able to cache any tokens. See
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/2503
   * @private
   */
  private readonly scopes = [`https://${CelumPropertiesProvider.properties.authentication.aDDomain}.onmicrosoft.com/scope/User.Read`];

  private isReady$ = new BehaviorSubject<boolean>(false);
  private readonly accountSubject$ = new BehaviorSubject<AccountInfo>(null);

  constructor(
    private translateService: TranslateService,
    private msalService: MsalService,
    private broadcastService: MsalBroadcastService,
    private userService: UserService,
    private loginRedirectService: LoginRedirectService,
    @Inject(AUTH_SERVICE_CONFIG) private serviceConfig: AuthServiceConfig
  ) {
    this.account$ = this.isReady$.pipe(
      isTruthy(),
      distinctUntilChanged(),
      switchMap(() => this.accountSubject$),
      map(account => {
        if (!account) {
          return null;
        }

        return {
          email: account.username,
          newUser: (account.idTokenClaims as any)[AuthService.NEW_USER_CLAIM]
        };
      })
    );
    this.account$.pipe(map(x => !!x)).subscribe(isAuthenticated => this.internalIsAuthenticated$.next(isAuthenticated));

    // Enable events related to locally stored accounts
    this.msalService.instance.enableAccountStorageEvents();

    /* ACCOUNT_REMOVED event is emitted when the user signs out from another tab, which will clear the login data for this application, but will not redirect to
       the login page. This event should allow us to actively navigate to the login page. */
    this.broadcastService.msalSubject$
      .pipe(
        filter(event => event.eventType === EventType.ACCOUNT_REMOVED),
        take(1)
      )
      .subscribe(() => {
        this.signOut();
      });

    // Set the active account AND the signIn cookie only once after a successful login, else correct detection of global signin is not possible
    this.broadcastService.msalSubject$
      .pipe(
        filter(event => event.eventType === EventType.LOGIN_SUCCESS),
        take(1)
      )
      .subscribe(() => {
        this.setActiveAccount();
      });

    // MSAL only provides correct information about account/login after handleRedirect has been handled
    this.msalService
      .handleRedirectObservable()
      .pipe(
        catchError(error => {
          console.error('AuthService: An error occurred while handling redirect observable', error);
          // User cancelled new user registration or consent dialog for new user registration?
          if (this.loginErrorCodes.some(errorCode => error.message.includes(errorCode))) {
            // Navigate back to the main page (else it would navigate to [baseAddress]/logged-in and stop there)
            window.location.assign('/');
          }
          return EMPTY;
        }),
        take(1)
      )
      .subscribe(result => {
        this.msalRedirectResponse$.next(result);
        this.finishServiceInitialization();
      });
  }

  public get isAuthenticated$(): Observable<boolean> {
    return this.internalIsAuthenticated$.asObservable();
  }

  /**
   * Sign in the user
   * @param restoredApplicationRedirectUri? - Where the application optionally redirects after the login process has completed. Allows to e.g. navigate back to a
   * subsection of the site after logging out and in again
   * @param policy - The policy used for the authentication process. Defaults to the one configured in the application properties
   * @param msalRedirectUri? - Allows overriding the redirect URI used by MSAL during the login process. Allows to redirect to a completely different page
   * after login (SACC uses this e.g. when the user wants to edit their profile details)
   * @param loginHint? - Optional login hint
   */
  public signIn(
    restoredApplicationRedirectUri?: string,
    policy: string = CelumPropertiesProvider.properties.authentication.defaultSigninPolicy,
    msalRedirectUri?: string,
    loginHint?: string
  ): void {
    this.authenticate(restoredApplicationRedirectUri, policy, msalRedirectUri, loginHint);
  }

  public setActiveAccount(): void {
    const account = this.getMsalAccount();

    if (account) {
      this.accountSubject$.next(account);
    }
  }

  public signOut(redirectURL?: string): Observable<void> {
    this.onSignOut?.();

    localStorage.removeItem(TenantService.TENANT_LOCAL_STORAGE_KEY);
    let postLogoutRedirectUri = CelumPropertiesProvider.properties.authentication.logoutRedirectUri;
    if (redirectURL) {
      postLogoutRedirectUri = redirectURL;
    }
    return this.msalService.logoutRedirect({ postLogoutRedirectUri, account: this.getMsalAccount() });
  }

  public getMsalAccount(): AccountInfo {
    return this.msalService.instance
      .getAllAccounts()
      .find(
        account =>
          (account.idTokenClaims?.[AuthService.TFP_CLAIM] as string)?.toLowerCase() ===
          CelumPropertiesProvider.properties.authentication.defaultSigninPolicy.toLowerCase()
      );
  }

  public getAuthResult(): Observable<AuthenticationResult> {
    const request: any = {
      account: this.getMsalAccount(),
      scopes: this.scopes
    };

    return this.getAuthResultFromOrderedProviders(request).pipe(
      catchError(error => {
        console.warn('AuthService: All token providers failed or exited unexpectedly', error);
        return EMPTY;
      })
    );
  }

  private finishServiceInitialization(): void {
    // Try to set the active account to signal whether the user is signed in or not
    this.setActiveAccount();

    if (this.serviceConfig.loadSaccUserAfterSignIn) {
      // Trigger loading the current user from SACC, so that information like their tenants or avatar are available to the app
      this.userService
        .loadCurrentUser()
        .pipe(
          catchError(error => {
            console.error('AuthService: Error while fetching current user data', error);
            // Convert the error. The application then has to specifically react to it (e.g. by using the AppErrorHandler of shared/util/)
            return throwError(() => new UserInfoLoadingError(error.message));
          })
        )
        .subscribe(() => {
          // From this point on the AuthService is initialized
          this.isReady$.next(true);
        });
    } else {
      this.isReady$.next(true);
    }
  }

  /**
   * Starts the authentication process
   * @param restoredApplicationRedirectUri - The url the application will redirect to after login was completed. This is stored e.g. when the user logs out
   * on a subpage of the application, and we want to go back there again after logging in
   * @param policy - The policy used for the authentication process
   * @param msalRedirectUri - Redirect done by MSAL after the login. Can be overridden to navigate to some other url than the one in the application properties.
   * @param loginHint? - Optional login hint
   * @param prompt? - Optional parameter to control which kind of user interaction is required during authentication
   * (see https://learn.microsoft.com/en-us/azure/active-directory/develop/msal-js-prompt-behavior)
   */
  private authenticate(
    restoredApplicationRedirectUri: string,
    policy: string,
    msalRedirectUri: string = CelumPropertiesProvider.properties.authentication.redirectUri,
    loginHint?: string,
    prompt?: string
  ): void {
    // Optional redirect after logging in. This allows to e.g. navigate back to a sub-page after logging out and in again!
    const finalRedirectUriAfterLoginComponent = !DataUtil.isEmpty(restoredApplicationRedirectUri)
      ? restoredApplicationRedirectUri
      : window.location.pathname + window.location.search;

    this.loginRedirectService.storeLoginRedirectUri(finalRedirectUriAfterLoginComponent);

    let redirectUri = msalRedirectUri;
    let state: string = undefined;

    if (isCustomDomainRedirect(window.location.href)) {
      const stateObject: CustomDomainAuthFlowState = {
        originatingWindowLocationHref: window.location.href,
        redirectUriAfterLogin: `${window.location.protocol}//${window.location.host}/logged-in`
      };

      state = btoa(JSON.stringify(stateObject));
      redirectUri = `${msalRedirectUri}-proxy`;
    }

    // eslint-disable-next-line camelcase
    const extraQueryParameters = { ui_locales: this.translateService.currentLang };
    const request: RedirectRequest = {
      authority: `https://${CelumPropertiesProvider.properties.authentication.b2cDomain}/${CelumPropertiesProvider.properties.authentication.b2cTenantId}/${policy}`,
      redirectUri,
      scopes: this.scopes,
      loginHint,
      prompt,
      ...(state && { state }),
      extraQueryParameters
    };

    this.msalService
      .loginRedirect(request)
      .pipe(
        catchError(error => {
          console.error('AuthService: An error occurred during redirecting', error);
          return EMPTY;
        })
      )
      .subscribe();
  }

  private getAuthResultFromOrderedProviders(request: BaseAuthRequest): Observable<AuthenticationResult> {
    const providersStream = concat(...tokenProviders(this.msalService, request, this.loginRedirectService)).pipe(isTruthy());

    return providersStream.pipe(
      first(value => !DataUtil.isEmpty(value.idToken)),
      catchError(error => {
        if (error instanceof EmptyError) {
          return throwError(() => new Error('AuthService: Could not obtain token from any token provider'));
        }

        console.error('AuthService: Failed to obtain token', error);
        return throwError(() => error);
      })
    );
  }
}
