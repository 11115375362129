import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

/**
 * This directive emits 'longPress' event after given duration when the element is tapped on a touch device
 */
@Directive({ selector: '[long-press]', standalone: true })
export class LongPressDirective implements OnInit {

  /** defines how much time (in ms) should pass before the longPress event is emitted */
  @Input() public duration = 400;

  /** event emitted when the element is long pressed */
  @Output() public readonly longPress: EventEmitter<any> = new EventEmitter();

  private touchTimeout: any;

  constructor(private element: ElementRef) {
  }

  public ngOnInit(): void {
    const el = this.element.nativeElement as HTMLElement;
    el.addEventListener('touchstart', () => this.touchstart(), { passive: true });
    el.addEventListener('touchend', () => this.touchEnd(), { passive: true });
    el.addEventListener('touchcancel', () => this.touchEnd(), { passive: true });
    el.addEventListener('touchmove', () => this.touchEnd(), { passive: true });
  }

  public touchstart(): void {
    this.touchTimeout = setTimeout(() => {
      this.longPress.emit({});
    }, this.duration);
  }

  private touchEnd(): void {
    clearTimeout(this.touchTimeout);
  }

}
