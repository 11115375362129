import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { GeneralSettings, PortalConfiguration, WidgetConfigMap, WidgetIdentifier } from '@celum/portals/domain';

import { ConfigurationResolver } from './configuration-resolver';
import { ConfigurationService } from '../model/configuration.service';

@Injectable()
export class ReadonlyConfigurationService implements ConfigurationService {
  public generalSettings$ = new BehaviorSubject<GeneralSettings>(null);
  public portalConfiguration$ = new BehaviorSubject<PortalConfiguration>(null);
  public widgets$ = new BehaviorSubject<WidgetIdentifier[]>(null);
  public widgetConfigs$ = new BehaviorSubject<WidgetConfigMap>(null);
  public portalId$ = new BehaviorSubject<string>(null);

  constructor(configurationResolver: ConfigurationResolver) {
    const config = configurationResolver.configuration$.getValue();

    this.portalId$.next(config.id);
    this.portalConfiguration$.next(config);
    this.generalSettings$.next(config.configuration.general.settings);
    this.widgets$.next([
      ...(config.configuration.general.widgets.header ? [config.configuration.general.widgets.header] : []),
      ...config.configuration.pages[0].widgets
    ]);
    this.widgetConfigs$.next(config.configuration.widgetMap);
  }

  public selectWidget(widgetId: string): void {
    console.warn('You are not in edit mode! "selectWidget" does nothing in readonly mode!');
  }

  public clearSelectedWidget(): void {
    console.warn('You are not in edit mode! "clearSelectedWidget" does nothing in readonly mode!');
  }

  public getPortalId(): string {
    return this.portalId$.value;
  }
}
