import { ExecutableAction, LocalizedValue } from '@celum/core';

import { ColorConstants } from '../../../base/color-constants';
import { IconConfiguration } from '../../../icon/index';

export enum SnackbarState {
  PREPARATION, PROGRESS, SUCCESS, ERROR, WARNING
}

export type SnackbarStateDependentActions = { [K in SnackbarState]?: ExecutableAction[] };
export type SnackbarButtonConfig = {
  action: ExecutableAction, icon?: IconConfiguration, text: string, buttonColor: string, loading?: boolean, disabled?: boolean, identifier?: string
};

export class SnackbarEntry {
  public title: string | LocalizedValue;
  public titleParams: object;

  constructor(title: string | LocalizedValue, titleParams?: object) {
    this.title = title;
    this.titleParams = titleParams;
  }
}

export class SnackbarConfiguration {

  public actions: SnackbarStateDependentActions = {};
  public autoModifyIconColor = true;
  public autoVanish = true;
  public autoVanishTime = 7000;
  public onVanishCallback: () => void;
  public bubble: string;
  public dismissible = true;
  public onDismissCallback: () => void;
  public expanded = false;
  public expandable = false;
  public expandIconSize = 22;
  public icon: IconConfiguration;
  public identifier: string;
  public status: SnackbarState;
  public titles: SnackbarEntry[] = [];
  public descriptions: SnackbarEntry[] = [];
  public buttons: SnackbarButtonConfig[] = [];
  public showToggleBeforeActions = false;
  public iconBackgroundColor: string;

  constructor(status: SnackbarState, titles: SnackbarEntry[]) {
    this.status = status;
    this.titles = titles;
  }

  public withStatus(status: SnackbarState): this {
    this.status = status;
    return this;
  }

  public withTitle(title: string | LocalizedValue, titleParams?: object): this {
    this.titles = [new SnackbarEntry(title, titleParams)];
    return this;
  }

  public withDescription(description: string, descriptionParams?: object): this {
    this.descriptions = [new SnackbarEntry(description, descriptionParams)];
    return this;
  }

  public withIcon(icon: IconConfiguration): this {
    this.icon = icon;
    return this;
  }

  public withIconBackgroundColor(color: string): this {
    this.iconBackgroundColor = color;
    return this;
  }

  public withActions(actions: SnackbarStateDependentActions): this {
    this.actions = actions;
    return this;
  }

  public withActionButtons(buttons: SnackbarButtonConfig[]): this {
    this.buttons = buttons;
    return this;
  }

  public withAutoModifyIconColor(autoModify: boolean): this {
    this.autoModifyIconColor = autoModify;
    return this;
  }

  public withAutoVanish(autoVanish: boolean): this {
    this.autoVanish = autoVanish;
    return this;
  }

  public withAutoVanishTime(vanishTime: number): this {
    this.autoVanishTime = vanishTime;
    return this;
  }

  /**
   * Register a callback function which is called if the snackbar is automatically vanished.
   * The function will not be called it the snackbar is manually dismissed by the user, use {@link onDismiss} to react on this.
   * @param callback function which will be called if the snackbar vanishes
   */
  public onVanish(callback: () => void): this {
    this.onVanishCallback = callback;
    return this;
  }

  public withExpandable(expandable: boolean, iconSize = 10): this {
    this.expandable = expandable;
    this.expandIconSize = iconSize;
    return this;
  }

  public withExpanded(expanded: boolean): this {
    this.expanded = expanded;
    return this;
  }

  public withDismissible(dismissible: boolean): this {
    this.dismissible = dismissible;
    return this;
  }

  /**
   * Register a callback function which is called if the user manually dismisses the snackbar.
   * The function will not be called it the snackbar vanishes automatically, use {@link onVanish} to react on this.
   * @param callback function which will be called if the snackbar is dismissed
   */
  public onDismiss(callback: () => void): this {
    this.onDismissCallback = callback;
    return this;
  }

  public withBubble(bubble: string): this {
    this.bubble = bubble;
    return this;
  }

  public withIdentifier(identifier: string): this {
    this.identifier = identifier;
    return this;
  }

  public withShowToggleBeforeActions(showBefore: boolean): this {
    this.showToggleBeforeActions = showBefore;
    return this;
  }

  public static get successIcon(): IconConfiguration {
    return IconConfiguration.small('check-circle-m', '').withIconSize(20).withColor(ColorConstants.SYSTEM_GREEN);
  }

  public static get errorIcon(): IconConfiguration {
    return IconConfiguration.small('error-m', '').withIconSize(20).withColor(ColorConstants.SYSTEM_RED);
  }

  public static get informationIcon(): IconConfiguration {
    return IconConfiguration.small('info-icon', '').withIconSize(20).withColor(ColorConstants.PRIMARY);
  }

  public static get errorOnBackgroundIcon(): IconConfiguration {
    return IconConfiguration.large('error-circle-m', '').withColor(ColorConstants.SYSTEM_WHITE);
  }

  public static get warnOnBackgroundIcon(): IconConfiguration {
    return IconConfiguration.large('attention-m', '').withColor(ColorConstants.SYSTEM_WHITE);
  }

  public static basic(status: SnackbarState, title: string | LocalizedValue, titleParams?: object): SnackbarConfiguration {
    return new SnackbarConfiguration(status, [new SnackbarEntry(title, titleParams)]).withAutoVanish(true);
  }

  public static standard(title: string | LocalizedValue, titleParams?: object): SnackbarConfiguration {
    return new SnackbarConfiguration(SnackbarState.PROGRESS, [new SnackbarEntry(title, titleParams)])
      .withIcon(SnackbarConfiguration.informationIcon.withColor(ColorConstants.SYSTEM_WHITE))
      .withAutoModifyIconColor(false)
      .withAutoVanish(true);
  }

  public static success(title: string | LocalizedValue, titleParams?: object): SnackbarConfiguration {
    return new SnackbarConfiguration(SnackbarState.SUCCESS, [new SnackbarEntry(title, titleParams)])
      .withIcon(SnackbarConfiguration.successIcon)
      .withAutoVanish(true);
  }

  public static error(title: string | LocalizedValue, options?: { titleParams?: object, autoVanish?: boolean }): SnackbarConfiguration {
    return new SnackbarConfiguration(SnackbarState.ERROR, [new SnackbarEntry(title, options?.titleParams)])
      .withIcon(SnackbarConfiguration.errorOnBackgroundIcon.withColor(ColorConstants.SYSTEM_WHITE))
      .withAutoModifyIconColor(false)
      .withIconBackgroundColor(ColorConstants.ERROR)
      .withAutoVanish(options?.autoVanish ?? false);
  }

  public static warn(title: string | LocalizedValue, options?: { titleParams?: object, autoVanish?: boolean }): SnackbarConfiguration {
    return new SnackbarConfiguration(SnackbarState.WARNING, [new SnackbarEntry(title, options?.titleParams)])
      .withIcon(SnackbarConfiguration.warnOnBackgroundIcon.withColor(ColorConstants.SYSTEM_WHITE))
      .withAutoModifyIconColor(false)
      .withIconBackgroundColor(ColorConstants.WARNING)
      .withAutoVanish(options?.autoVanish ?? false);
  }

  public static info(title: string | LocalizedValue, titleParams?: object): SnackbarConfiguration {
    return new SnackbarConfiguration(SnackbarState.PROGRESS, [new SnackbarEntry(title, titleParams)])
      .withIcon(SnackbarConfiguration.informationIcon.withColor(ColorConstants.SYSTEM_WHITE))
      .withAutoModifyIconColor(false)
      .withIconBackgroundColor(ColorConstants.PRIMARY)
      .withAutoVanish(false);
  }

  public static progress(title: string | LocalizedValue, titleParams?: object, mode?: 'determinate' | 'indeterminate',
                         value?: number): ProgressSnackbarConfiguration {
    return new ProgressSnackbarConfiguration(SnackbarState.PROGRESS, [new SnackbarEntry(title, titleParams)])
      .withMode(mode)
      .withValue(value)
      .withAutoVanish(false);
  }

  public static errors(titles?: SnackbarEntry[]): SnackbarConfiguration {
    return new SnackbarConfiguration(SnackbarState.ERROR, titles)
      .withIcon(SnackbarConfiguration.errorOnBackgroundIcon.withColor(ColorConstants.SYSTEM_WHITE))
      .withAutoModifyIconColor(false)
      .withIconBackgroundColor(ColorConstants.ERROR).withAutoVanish(false);
  }
}

export class ProgressSnackbarConfiguration extends SnackbarConfiguration {
  public mode: 'determinate' | 'indeterminate' = 'indeterminate';

  /**
   * Current progress (0 - 100)
   */
  public value: number;

  public withMode(mode: 'determinate' | 'indeterminate'): ProgressSnackbarConfiguration {
    this.mode = mode;
    return this;
  }

  public withValue(value: number): ProgressSnackbarConfiguration {
    this.value = value;
    return this;
  }
}
