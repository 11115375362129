import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { combineLatest, distinctUntilChanged, filter, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { DataUtil } from '@celum/core';
import { BrowserUtil, TranslationHelper } from '@celum/ng2base';

import { LicenseType } from '../model/license.model';
import { UserDetails } from '../model/user.model';
import { AuthService } from '../services/auth.service';
import { LoginRedirectService } from '../services/login-redirect.service';
import { TenantService } from '../services/tenant.service';
import { UserService } from '../services/user.service';

/**
 * AuthGuard that will "just" check for authentication. Use functional AuthGuard if licenseCheck is also needed
 */
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    protected router: Router,
    private loginRedirectService: LoginRedirectService
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isAuthenticated$.pipe(
      distinctUntilChanged(),
      tap(isAuthenticated => {
        if (!isAuthenticated) {
          this.authService.signIn(state.url);
        } else {
          const redirectUri = this.loginRedirectService.getLoginRedirectUri();
          this.loginRedirectService.clearLoginRedirectUri();
          // If we have stored e.g. a redirect to a subpage during logging out, redirect there
          !DataUtil.isEmpty(redirectUri) && this.router.navigateByUrl(redirectUri);
        }
      })
    );
  }
}

/**
 * Fully fledged Authentication Guard. Checks whether the user is authenticated, and also checks for valid licenses
 */
export const authGuardWithLicenceCheck = (
  state: RouterStateSnapshot,
  licenseType: LicenseType,
  fallbackUrlsPerLanguage: { [language: string]: string },
  authService = inject(AuthService),
  router = inject(Router),
  userService = inject(UserService),
  translation = inject(TranslationHelper),
  tenantService = inject(TenantService),
  loginRedirectService = inject(LoginRedirectService)
) => {
  return combineLatest([authService.isAuthenticated$, userService.currentUser$]).pipe(
    distinctUntilChanged(),
    tap(([isAuthenticated, _]) => {
      if (!isAuthenticated) {
        authService.signIn(state.url);
      } else {
        const redirectUri = loginRedirectService.getLoginRedirectUri();
        loginRedirectService.clearLoginRedirectUri();
        // If we have stored e.g. a redirect to a subpage during logging out, redirect there
        !DataUtil.isEmpty(redirectUri) && router.navigateByUrl(redirectUri);
      }
    }),
    // If not redirected previously, check if user has valid license
    filter(([_, user]) => !!user),
    map(([isAuthenticated, user]) => {
      // If the user has no valid license at all, redirect to fallbackUrl
      if (!userHasValidLicenseForApp(user, licenseType)) {
        BrowserUtil.openUrl(fallbackUrlsPerLanguage[translation.locale].replace('${language}', translation.locale));
        return false;
      }

      // Now check if the currently selected tenantId has a valid license. If not, switch to the first valid tenant
      ensureValidTenantSelection(tenantService, user, licenseType);

      return isAuthenticated;
    })
  );
};

function userHasValidLicenseForApp(user: UserDetails, licenseType: LicenseType): boolean {
  return licenseType === LicenseType.NO_LICENSE || user.accountAccesses.some(access => UserService.getValidLicenseByType(access, licenseType) !== undefined);
}

function ensureValidTenantSelection(tenantService: TenantService, user: UserDetails, licenseType: LicenseType): void {
  const currentTenantId = tenantService.getCurrentTenantId() || tenantService.getTenantIdFromUrl();
  if (currentTenantId) {
    const sortedAccesses = user.accountAccesses.sort((a, b) => a.accountName.localeCompare(b.accountName));
    const currentAccountAccess = sortedAccesses.find(access => access.accountId === currentTenantId);
    if (!currentAccountAccess || !UserService.getValidLicenseByType(currentAccountAccess, licenseType)) {
      const nextValidAccountAccess = sortedAccesses.find(access => UserService.getValidLicenseByType(access, licenseType));
      if (nextValidAccountAccess) {
        tenantService.switchTenant(nextValidAccountAccess.accountId, true);
      }
    }
  }
}
