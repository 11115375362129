import { Directive, OnDestroy } from '@angular/core';
import { MonoTypeOperatorFunction, Subject, takeUntil } from 'rxjs';

/**
 * Base class for reactive components. Use it if you have to manage subscriptions manually as it provides common reactive patterns.
 * @deprecated use `takeUntilDestroyed()` instead.
 *
 *   ```ts
 *   // deprecated
 *   class MyComponent extends ReactiveComponent
 *   stream$.pipe(takeUntil(this.unsubscribe$)).subscribe();
 *
 *   // use instead: injection context
 *   stream$.pipe(takeUntilDestroyed()).subscribe();
 *
 *  // use instead: non injection context
 *   private destroyRef = inject(DestroyRef);
 *   stream$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
 *   ```
 */
@Directive({ selector: '[reactiveComponent]' })
export abstract class ReactiveComponent implements OnDestroy {
  /**
   * Emits to notify that subscribers should unsubscribe, eg. if a component is destroyed or parts of a component are hidden.
   */
  protected unsubscribe$ = new Subject<void>();

  /**
   * Returns a function that unsubscribes when the component is destroyed.
   * Short for `takeUntil(this.unsubscribe$)`.
   * @deprecated use `takeUntilDestroyed()` instead, see deprecation information on {@link ReactiveComponent} for more details
   */
  public takeUntilDestroy(): MonoTypeOperatorFunction<unknown> {
    return takeUntil(this.unsubscribe$);
  }

  /**
   * When an angular component is destroyed, notify subscribers and complete
   */
  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
