import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { ExecutableAction } from '@celum/core';
import { ItemProgress, ProgressStatus } from '@celum/ng2base';

import { ColorConstants } from '../../../base/color-constants';
import { IconConfiguration } from '../../../icon/index';

export type StateDependentActions = { [K in ProgressStatus]?: ExecutableAction[] };

@Component({
             selector: 'progress-item',
             templateUrl: './progress-item.html',
             styleUrls: ['./progress-item.less']
           })
export class ProgressItem implements OnChanges {

  @Input() public actions: StateDependentActions;
  @Input() public item: ItemProgress;
  @Input() public name: string;

  /**
   * @deprecated never emits
   */
  @Output() public readonly actionClicked = new EventEmitter<string>();

  public currentActions: ExecutableAction[] = [];
  public finishedIcon: IconConfiguration;

  public ngOnChanges({ actions, item }: SimpleChanges): void {
    if (item && item.currentValue !== item.previousValue || actions && actions.currentValue !== actions.previousValue) {
      this.updateItem();
    }
  }

  /**
   * Try to get something useful out of the error and display it as a tooltip
   * @param error which should be visualized
   */
  public getErrorTooltip(error: any): string {
    return !error ? '' :
           typeof error === 'string' ? error :
           error.message ? error.message :
           error.messageKey ? error.messageKey : '';
  }

  private updateItem(): void {
    this.currentActions = this.getActionsByStatus(this.item.status);
    this.finishedIcon = ProgressItem.getIconByStatus(this.item.status);
  }

  private getActionsByStatus(status: ProgressStatus): ExecutableAction<string>[] {
    if (this.item.status === ProgressStatus.Queued || this.item.status === ProgressStatus.Running) {
      return this.actions && [...this.actions[ProgressStatus.Queued] || [], ...this.actions[ProgressStatus.Running] || []] || [];
    }

    return this.actions?.[status] || [];
  }

  private static getIconByStatus(status: ProgressStatus): IconConfiguration {
    const errorIcon = IconConfiguration.small('error-m', '').withIconSize(20).withColor(ColorConstants.SYSTEM_RED);
    const successIcon = IconConfiguration.small('check-circle-m', '').withIconSize(20).withColor(ColorConstants.SYSTEM_GREEN);
    return status === ProgressStatus.Error ? errorIcon : status === ProgressStatus.Success ? successIcon : undefined;
  }
}
