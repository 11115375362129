import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { format as dateFormat, differenceInCalendarDays, formatDistance, formatRelative } from 'date-fns';
import { firstValueFrom } from 'rxjs';

import { DateTimeFormats, DateUtil } from './date/date-util';

/**
 * DateService providing date parsing and other date utilities based on the current selected locale. This depends on some other service to keep
 * {@link DateService.GLOBAL_LOCALE} in sync with the selected locale.
 *
 *
 * It also exposes custom date and time formats as static variables that are used as defaults for its methods. These variables can/should be set from some
 * global service.
 *
 * If you do not depend on custom formats and/or you do not want to rely on {@link DateService.GLOBAL_LOCALE} to be in sync with the current selected locale,
 * consider using {@link DateUtil} instead which has default formats and always expects a locale to be passed.
 */
@Injectable({ providedIn: 'root' })
export class DateService {
  public static GLOBAL_LOCALE = 'en';

  public static CUSTOM_DATE_FORMAT_EN = DateUtil.DEFAULT_DATE_FORMAT_EN;
  public static CUSTOM_DATE_FORMAT_DE = DateUtil.DEFAULT_DATE_FORMAT_DE;
  public static CUSTOM_DATE_FORMAT_FR = DateUtil.DEFAULT_DATE_FORMAT_FR;
  public static CUSTOM_DATE_FORMAT_IT = DateUtil.DEFAULT_DATE_FORMAT_IT;
  public static CUSTOM_DATE_FORMAT_ES = DateUtil.DEFAULT_DATE_FORMAT_ES;

  public static CUSTOM_TIME_FORMAT_EN = DateUtil.DEFAULT_TIME_FORMAT_EN;
  public static CUSTOM_TIME_FORMAT_DE = DateUtil.DEFAULT_TIME_FORMAT_DE;
  public static CUSTOM_TIME_FORMAT_FR = DateUtil.DEFAULT_TIME_FORMAT_FR;
  public static CUSTOM_TIME_FORMAT_IT = DateUtil.DEFAULT_TIME_FORMAT_IT;
  public static CUSTOM_TIME_FORMAT_ES = DateUtil.DEFAULT_TIME_FORMAT_ES;

  private sameDay = '';
  private nextDay = '';
  private lastDay = '';
  private taskDateFormat = 'MMMM do hh:mm';

  constructor(private translateService: TranslateService) {
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      if (event.lang === 'en') {
        this.taskDateFormat = 'MMMM do hh:mm';
      } else if (event.lang === 'de') {
        this.taskDateFormat = 'do MMMM hh:mm';
      }

      firstValueFrom(this.translateService.get('DATE.TODAY')).then(message => {
        this.sameDay = message;
      });
      firstValueFrom(this.translateService.get('DATE.TOMORROW')).then(message => {
        this.nextDay = message;
      });
      firstValueFrom(this.translateService.get('DATE.YESTERDAY')).then(message => {
        this.lastDay = message;
      });
    });
  }

  public getFormattedDateTask(dateAsNumber: number): string {
    const date = new Date(dateAsNumber);
    const diffDays = Math.abs(differenceInCalendarDays(date, new Date()));

    if (diffDays > 7) {
      return dateFormat(date, this.taskDateFormat, { locale: DateUtil.mapStringToLocale(DateService.GLOBAL_LOCALE) });
    } else {
      return formatRelative(date, new Date(), { locale: DateUtil.mapStringToLocale(DateService.GLOBAL_LOCALE) });
    }
  }

  public getTimeUntil(dueDateTime: number): string {
    const dueDate = new Date(dueDateTime);
    dueDate.setHours(0, 0, 0, 0);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (dueDate.getTime() === today.getTime()) {
      return this.sameDay;
    } else if (DateUtil.yesterdayOrTomorrow(dueDate, today)) {
      const yesterdayOrTomorrow = formatRelative(dueDate, today, { locale: DateUtil.mapStringToLocale(DateService.GLOBAL_LOCALE) });

      // exactly yesterday or tomorrow
      if (yesterdayOrTomorrow.startsWith('tomorrow')) {
        return this.nextDay;
      } else if (yesterdayOrTomorrow.startsWith('yesterday')) {
        return this.lastDay;
      }

      return '';
    } else {
      return formatDistance(dueDate, today, { addSuffix: true, locale: DateUtil.mapStringToLocale(DateService.GLOBAL_LOCALE) });
    }
  }

  /**
   * @param date          the date to format
   * @param currentLocale the current locale, provide "undefined", if you want to fallback to the current locale
   * @param format        optional define the format to use (default is numeric)
   * @param showTime      whether to show the time as well (default: only the date)
   */
  public static formatDateForGivenLocale(
    date: number,
    currentLocale: string = DateService.GLOBAL_LOCALE,
    format: string = DateUtil.FORMATS.DATE.NUMERIC,
    showTime = false
  ): string {
    return DateUtil.handleDateFormat(new Date(date), format, DateService.getCustomFormats(), currentLocale, showTime);
  }

  public static addTimeToGivenFormat(format: string, locale: string = DateService.GLOBAL_LOCALE): string {
    const localeToUse = ['de', 'fr', 'en'].includes(locale) ? locale : 'en';

    return `${format} ${DateService.getCustomFormats()[localeToUse].time}`;
  }

  public static format(date: number, format: string, showTime = false): string {
    return DateUtil.handleDateFormat(new Date(date), format, DateService.getCustomFormats(), DateService.GLOBAL_LOCALE, showTime);
  }

  public static handleDateFormat(date: Date, format: string, locale: string = DateService.GLOBAL_LOCALE, showTime?: boolean): string {
    return DateUtil.handleDateFormat(date, format, DateService.getCustomFormats(), locale, showTime);
  }

  public static convertToDateNumeric(value: string, locale: string = DateService.GLOBAL_LOCALE): Date {
    return DateUtil.convertToDateNumeric(value, DateService.getCustomFormats(), locale);
  }

  public static getFormat(localeString?: string, withTime = false): string {
    const customFormats = DateService.getCustomFormats();
    const locale = localeString ? localeString : DateService.GLOBAL_LOCALE;
    return DateUtil.getFormat(locale, customFormats, withTime);
  }

  public static getCustomFormats(): DateTimeFormats {
    return {
      en: {
        date: DateService.CUSTOM_DATE_FORMAT_EN,
        time: DateService.CUSTOM_TIME_FORMAT_EN
      },
      de: {
        date: DateService.CUSTOM_DATE_FORMAT_DE,
        time: DateService.CUSTOM_TIME_FORMAT_DE
      },
      fr: {
        date: DateService.CUSTOM_DATE_FORMAT_FR,
        time: DateService.CUSTOM_TIME_FORMAT_FR
      },
      it: {
        date: DateService.CUSTOM_DATE_FORMAT_IT,
        time: DateService.CUSTOM_TIME_FORMAT_IT
      },
      es: {
        date: DateService.CUSTOM_DATE_FORMAT_ES,
        time: DateService.CUSTOM_TIME_FORMAT_ES
      }
    };
  }
}
