import { Sortable } from '../../../extensions/extension/sortable';

export class GroupInfo implements Sortable {

  constructor(public groupId: string, public sortOrder: number) {

  }

  /**
   * @deprecated please use {@link groupId} property
   */
  public getGroupId(): string {
    return this.groupId;
  }

  /**
   * @deprecated please use {@link sortOrder} property
   */
  public getSortOrder(): number {
    return this.sortOrder;
  }
}
