import { bootstrapApplication } from '@angular/platform-browser';

import { prepareApplicationInsights } from '@celum/shared/domain';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

prepareApplicationInsights()
  .then(() => bootstrapApplication(AppComponent, appConfig))
  .catch(err => console.error(err));
