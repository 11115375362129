import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataContext } from './context/data-context';
import { OperationFilterCustomization } from './extension/operation-filter-customization';
import { GroupInfo } from './group/group-info';
import { CustomizerScope } from './model/customizer-scope';
import { GroupDefinition } from './model/group-definition';
import { Operation } from './model/operation';
import { OperationDefinition } from './model/operation-definition';
import { OperationStoreDefinition } from './model/operation-store-definition';
import { OperationsMap } from './model/operations-map';
import { CelumMap } from '../../core/common/celum-map';
import { Context } from '../../core/common/context';
import { AnyEntity, EntityType, NoEntity } from '../../core/model/entity-type';
import { flattenObservableArray } from '../../core/rxjs/rxjs-util';
import { DataUtil } from '../../core/util/data-util';
import { registerCustomizationType } from '../../extensions/customization/customization-type-annotation';
import { Registry } from '../../extensions/registry';
import { callExternalMethod } from '../../extensions/util/extension-util';

class OperationsManager {

  private static operationMap: Map<string, OperationsMap> = new Map<string, OperationsMap>();
  private static operations: Map<string, Operation> = new Map<string, Operation>();
  private static groupMap: Map<string, GroupDefinition> = new Map<string, GroupDefinition>();

  public static registerOperationsForTypes(contexts: Context[], entityTypes: EntityType[], operationDefinitions: OperationDefinition[]): void {
    contexts.forEach(context => {
      if (!this.operationMap.has(context.getId())) {
        this.operationMap.set(context.getId(), new OperationsMap());
      }

      const contextMap = OperationsManager.operationMap.get(context.getId());

      entityTypes.forEach(entityType => {
        if (!contextMap.has(entityType)) {
          contextMap.set(entityType, []);
        }

        const alreadyRegisteredOperations = contextMap.get(entityType);

        // only register operations that were not already registered...
        const notYetRegisteredOperations = operationDefinitions.filter(
          op => op.operation.overrideExistingOperation?.() || !alreadyRegisteredOperations.find(def => def.operation.getKey() === op.operation.getKey()));

        if (notYetRegisteredOperations.length !== operationDefinitions.length) {
          this.printAlreadyRegisteredOperations(operationDefinitions, alreadyRegisteredOperations, notYetRegisteredOperations);
        }

        notYetRegisteredOperations.forEach(definition => {
          const groupKey = definition.groupInfo.getGroupId();
          if (!this.groupMap.has(groupKey)) {
            console.warn(`OperationsManager: Group with the key ${groupKey} does not exist! The operation will be added to catch all group!`);
            definition.groupInfo = new GroupInfo('catchAllGroup', -1);
          }
          const operation = definition.operation;
          this.operations.set(operation.getKey(), operation);
        });

        contextMap.set(entityType, alreadyRegisteredOperations.concat(notYetRegisteredOperations));
      });
    });
  }

  public static registerGroup(groupDefinition: GroupDefinition): void {
    const groupKey = groupDefinition.group.getKey();

    if (this.groupMap.has(groupKey)) {
      console.warn(
        `OperationsManager: Group with the key ${groupKey} was already added! Existing one will be overridden with: ${JSON.stringify(groupDefinition)}`);
    }

    this.groupMap.set(groupKey, groupDefinition);
  }

  /**
   * Request all available operations based on the passed context and the entityTypes defined in the passed DataContext.
   */
  public static getAvailableOperationsForContext(context: Context, data: DataContext): Observable<OperationDefinition[]> {
    const entityTypes = [].concat(data.getEntityTypes());
    return this.getAvailableOperations([context], entityTypes, data);
  }

  /**
   * Request all available operations based on the passed contexts and the entityTypes defined in the passed DataContext.
   */
  public static getAvailableOperationsForContexts(contexts: Context[], data: DataContext): Observable<OperationDefinition[]> {
    const entityTypes = [].concat(data.getEntityTypes());
    return this.getAvailableOperations(contexts, entityTypes, data);
  }

  /**
   * Request all available operations based on the passed context, the passed entityTypes and the given DataContext.
   */
  public static getAvailableOperationsForEntityTypes(context: Context, entityTypes: EntityType[], data: DataContext): Observable<OperationDefinition[]> {
    return this.getAvailableOperations([context], entityTypes, data);
  }

  /**
   * Request all available operations based on the passed contexts, the passed entityTypes and the given DataContext.
   */
  public static getAvailableOperations(contexts: Context[], entityTypes: EntityType[], data: DataContext): Observable<OperationDefinition[]> {
    const orderedContexts = Context.sortAscending([...contexts]);
    return this.getOperations(orderedContexts, entityTypes, data);
  }

  public static getGroup(groupId: string): GroupDefinition {
    return this.groupMap.get(groupId);
  }

  public static storeDefinitionToOperationDefinition(storeDefinition: OperationStoreDefinition): OperationDefinition {
    return {
      priority: storeDefinition.priority,
      groupInfo: storeDefinition.groupInfo,
      operation: this.operations.get(storeDefinition.operationKey)
    };
  }

  /**
   * @internal
   * used for testing only
   */
  public static reset(): void {
    this.operationMap = new Map<string, OperationsMap>();
    this.operations = new Map<string, Operation>();
    this.groupMap = new Map<string, GroupDefinition>();
  }

  private static printAlreadyRegisteredOperations(operationDefinitions: OperationDefinition[], alreadyRegisteredOperations: OperationDefinition[],
                                                  notYetRegisteredOperations: OperationDefinition[]): void {
    const registeredTwice = operationDefinitions.filter(
      op => !!alreadyRegisteredOperations.find(def => def.operation.getKey() === op.operation.getKey()));

    console.warn(`OperationsManager: ${operationDefinitions.length -
                                       notYetRegisteredOperations.length} operations were already defined and are therefore not added again: ${JSON.stringify(
      registeredTwice.map(def => def.operation.getKey()))}`);
  }

  /**
   * Return all defined operations for all passed contexts and entity types. It is only considered that an operation is not added twice per entity type.
   */
  private static getOperationsForAllContexts(contexts: Context[], entityTypes: EntityType[]): OperationsPerEntityTypeMap {
    const operationsMap = this.operationMap;

    const operationsPerEntityType: OperationsPerEntityTypeMap = new OperationsPerEntityTypeMap();

    contexts.forEach(context => {
      const contextMap = operationsMap.get(context.getId());

      if (contextMap) {
        entityTypes.forEach(entityType => {
          const operations = contextMap.get(entityType);

          if (operations) {
            operations.forEach(operation => {
              const opKey = operation.operation.getKey();

              if (!operationsPerEntityType.get(entityType)) {
                operationsPerEntityType.set(entityType, []);
              }

              // operation was not already defined for current entity type
              if (!operationsPerEntityType.get(entityType).find(opConfig => opConfig.operation.getKey() === opKey)) {
                operationsPerEntityType.get(entityType).push(operation);
              }
            });
          }
        });
      }
    });

    return operationsPerEntityType;
  }

  private static getOperations(contexts: Context[], entityTypes: EntityType[], data: DataContext): Observable<OperationDefinition[]> {
    // eslint-disable-next-line eqeqeq
    if (data == null) {
      console.error('OperationsManager: Context is null! Cannot determine any operations.');
      return of([]);
    }

    const types = [...entityTypes];
    // if no entity type is passed, check for operations registered to AnyEntity
    if (types.length === 0) {
      types.push(AnyEntity);
    }

    const operationsPerEntityType: OperationsPerEntityTypeMap = OperationsManager.getOperationsForAllContexts(contexts, types);

    let operations = OperationsManager.filterOperations(operationsPerEntityType);

    operations = this.addOperationsForSpecialEntityType(AnyEntity, types, contexts, operations);
    if (DataUtil.isEmpty(data.getSelection())) {
      operations = this.addOperationsForSpecialEntityType(NoEntity, types, contexts, operations);
    }

    return flattenObservableArray(operations.map(op => this.isOperationVisible(op, data, types, contexts)))
      .pipe(map(operationInfos => operationInfos.filter(op => op.isVisible).map(op => op.operation)));
  }

  private static isOperationVisible(operationDefinition: OperationDefinition, data: DataContext, types: EntityType[],
                                    contexts: Context[]): Observable<{ operation: OperationDefinition, isVisible: boolean }> {

    const visible$ = callExternalMethod(
      () => operationDefinition.operation.isVisible(data),
      (err: any) => {
        console.warn(`OperationsManager: "isVisible" method of operation ${operationDefinition.operation.getKey()} threw an error!`, err);
        return false;
      }
    );
    return visible$.pipe(
      map(isVisible => {
        if (!isVisible) {
          return false;
        }

        const customizers = this.getCustomizer(operationDefinition, types, contexts);

        if (customizers && customizers.length > 0) {
          // return false if any of the available customizers is NOT available for the specified data context
          return customizers.every(customizer => customizer.isAvailable(data));
        }

        return true;
      }),
      map(isVisible => ({
        operation: operationDefinition,
        isVisible
      })));
  }

  /**
   * Filter the passed operations so that only those operations remain that are defined for EACH passed entity type.
   */
  private static filterOperations(operationsPerEntityType: OperationsPerEntityTypeMap): OperationDefinition[] {
    let operations: OperationDefinition[] = [];

    if (operationsPerEntityType.size() > 0) {
      const valueIterator = operationsPerEntityType.values();
      let cur = valueIterator.next();

      if (!cur.done) {
        operations = cur.value;
        cur = valueIterator.next();
      }

      // only add operations that are already part of the list of "operations" - make sure that only operations remain that are defined for EACH entity type
      // passed!
      while (!cur.done) {
        operations = cur.value.filter(
          (opConfig: OperationDefinition) => !!operations.find((entry: OperationDefinition) => entry.operation === opConfig.operation));

        cur = valueIterator.next();
      }
    }

    return operations;
  }

  /**
   * If the entity types list does not yet contain the AnyEntity, add operations registered for AnyEntity
   */
  private static addOperationsForSpecialEntityType(specialType: EntityType, entityTypes: EntityType[], contexts: Context[],
                                                   operations: OperationDefinition[]): OperationDefinition[] {
    let modifiedOperations: OperationDefinition[] = operations;

    if (entityTypes.indexOf(specialType) < 0) {
      // collect operations for AnyEntity for each context...
      const operationsForAnyEntity: OperationDefinition[] = contexts.map(context => {
        const contextMap = OperationsManager.operationMap.get(context.getId());
        return contextMap?.get(specialType) ?? [];
      }).reduce((previous, next) => [...previous, ...next], []);

      // if there was something registered, add AnyEntity to the list of entity types and add those operations not yet contained in the list of operations
      if (operationsForAnyEntity?.length) {
        entityTypes.push(specialType);

        modifiedOperations = operations.concat(operationsForAnyEntity.filter(
          (operationConfig: OperationDefinition) => !operations.find((config: OperationDefinition) => operationConfig.operation === config.operation)));
      }
    }

    return modifiedOperations;
  }

  private static getCustomizer(operation: OperationDefinition, entityTypes: EntityType[], contexts: Context[]): OperationFilterCustomization[] {
    return entityTypes.map(entityType => {
      return contexts.map(context => {
        const customizerScope = CustomizerScope.forOperation(entityType, context, operation.operation.getKey());
        return Registry.getCustomizer<OperationFilterCustomization>(OperationFilterCustomization.type, customizerScope, true, true);
      });
    }).reduce((previous, next) => [...previous, ...next], []).filter(customizer => !!customizer);
  }
}

registerCustomizationType(OperationFilterCustomization, OperationFilterCustomization.type);

class OperationsPerEntityTypeMap extends CelumMap<EntityType, OperationDefinition[]> {
  public translateKey(key: EntityType): string {
    return key.id;
  }
}

/**
 * Why is this necessary?
 *
 * Webpack creates a scope for each bundle it creates. All code you create only exists (and is accessible) inside of this scope. This also applies to classes
 * and their static properties/functions. For example, consider extensions for Nova. If you have extensions loaded that do NOT share their dependencies with
 * Nova, they will provide their own version of all classes and functions. These classes and functions only exist inside the scope of the extension (or the
 * extensions if some of them share dependencies). Even static classes. This proxy allows to make sure that static classes are actually behaving like you would
 * expect it (and as it would be if it wouldn't be for webpack) regardless of how many different webpack bundles are loaded on the side.
 */
let operationsManager = OperationsManager;

if (globalThis) {
  if (!(globalThis as any).CelumOperationsManager) {
    (globalThis as any).CelumOperationsManager = OperationsManager;
  } else {
    operationsManager = (globalThis as any).CelumOperationsManager;
  }
}

export { operationsManager as OperationsManager };
