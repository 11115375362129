import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { AfterContentInit, ContentChild, Directive, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import { MatInput } from '@angular/material/input';

export type CelumFormFieldSizes = 'xs' | 'small' | 'large' | 'medium' | 'xl' | 'emphasized';

@Directive({
             selector: '[celum-form-field]'
           })
export class CelumFormFieldDirective implements OnInit, AfterContentInit {

  @Input() public size: CelumFormFieldSizes = 'medium';
  @Input() public substrate!: 'light' | undefined;
  @Input() public isBackgroundWhite = false;
  @Input() public isAutosize = false;
  @Input() public reserveHintSpace = false; // set to true if you want to add mat-hint to this form field, if set to false, no space will be reserved

  @HostBinding('class.celum-input-form-field') public celumInputClass = true;
  @HostBinding('class.celum-input-form-field--xs') public celumInputXSClass = false;
  @HostBinding('class.celum-input-form-field--small') public celumInputSmallClass = false;
  @HostBinding('class.celum-input-form-field--medium') public celumInputMediumClass = false;
  @HostBinding('class.celum-input-form-field--emphasized') public celumInputEmphasizedClass = false;
  @HostBinding('class.celum-input-form-field--large') public celumInputLargeClass = false;
  @HostBinding('class.celum-input-form-field--xl') public celumInputXlClass = false;
  @HostBinding('class.celum-input-form-field--autosize') public celumInputAutosizeClass = false;
  @HostBinding('class.celum-input-form-field--reserve-hint-space') public celumInputReserveHintSpace = false;

  @HostBinding('class.celum-input-form-field--light-substrate') public isSubstrateLight = this.substrate === 'light';

  @ContentChild(CdkTextareaAutosize, { static: false }) public autoSize: CdkTextareaAutosize;
  @ContentChild(MatInput, {
    read: ElementRef,
    static: false
  }) public input: ElementRef;

  @HostBinding('class.celum-input-form-field--white-bg') public isBackgroundWhiteClass = false;

  public ngOnInit(): void {
    this.setFormFieldSize();
    this.setSubstrateType();
    this.isBackgroundWhiteClass = this.isBackgroundWhite;
  }

  public ngAfterContentInit(): void {
    // this section is relevant only for XL inputs and very likely temporary solution
    // Angular material doesn't consider multiple sizes for inputs, so doing max(min)Height calculations
    // it takes fixed height for calculation: @link:https://github.com/angular/components/blob/master/src/cdk/text-field/autosize.ts:169
    // So the next hack allows XL inputs to resize correctly.
    if (this.autoSize && this.celumInputXlClass) {
      // AM takes the default height for textarea 14px, so define it
      const MAT_TEXTAREA_CONSTANT_HEIGHT = 14;
      // this is pure height for XL textarea
      const TEXTAREA_HEIGHT = 39; // px. Turns out calculation with window.getComputedStyle time after time works wrong

      requestAnimationFrame(() => {
        // here the most tricky part
        // How AM calculate the max(min)Height, the formula is: max(min)Height = pureTextareaHeight (14px) * max(min)Rows
        // there is no restriction regarding that maxRows can't be double
        // which means that we can create coefficient which shows how many lines by 14px you need to cover defined amount of XL rows
        // So technically directive override defined in template @Input() maxRows with another amount (which is bigger one)

        // TODO: Definitely should come up with something more elegant
        this.autoSize.maxRows = this.autoSize.maxRows * (TEXTAREA_HEIGHT / MAT_TEXTAREA_CONSTANT_HEIGHT);
        this.autoSize.minRows = this.autoSize.minRows * (TEXTAREA_HEIGHT / MAT_TEXTAREA_CONSTANT_HEIGHT);
      });
    }
  }

  private setFormFieldSize(): void {
    switch (this.size) {
      case 'xs':
        this.celumInputXSClass = true;
        break;
      case 'small':
        this.celumInputSmallClass = true;
        break;
      case 'large':
        this.celumInputLargeClass = true;
        break;
      case 'xl':
        this.celumInputXlClass = true;
        break;
      case 'emphasized':
        this.celumInputEmphasizedClass = true;
        break;
      default:
        this.celumInputMediumClass = true;
    }

    this.celumInputAutosizeClass = Boolean(this.isAutosize);
    this.celumInputReserveHintSpace = Boolean(this.reserveHintSpace);
  }

  private setSubstrateType(): void {
    switch (this.substrate) {
      case 'light':
        this.isSubstrateLight = true;
        break;
    }
  }
}
