import { TranslateService } from '@ngx-translate/core';

import { LocalizedValue } from '@celum/core';

/**
 * Do an instant translation - this method is synchronous - you are responsible for knowing when the translations were loaded.
 * You can use the currentLocaleStream to find out when languages were loaded
 * @param translate translateService instance to use for translation
 * @param key message-key or localized value
 * @param displayLanguage current ui-language
 * @param defaultLanguage default language (used as a fallback)
 * @param targetLanguage translate to specific language - if this is set - it will not fallback to other languages
 * @param useFallbacks Specify if translation should fall back to other languages if there is no translation for wanted one
 * @param interpolationParams which will be used if string is passed as string
 */
export function instantTranslate(translate: TranslateService, key: string | LocalizedValue, displayLanguage?: string, defaultLanguage?: string,
                                 targetLanguage?: string, useFallbacks = true, interpolationParams?: object): string {
  if (typeof key === 'string') {
    return translate.instant(key, interpolationParams);
  } else {
    return evaluateLocalizedValue(key, targetLanguage, displayLanguage, defaultLanguage, useFallbacks);
  }
}

export function evaluateLocalizedValue(input: LocalizedValue, languageParam: string, displayLanguage: string, defaultLanguage: string,
                                       useFallbacks = true): string {
  if (!input) {
    return undefined;
  }

  if (!useFallbacks) {
    return input[languageParam];
  }

  return input[languageParam] || input[displayLanguage] || input[defaultLanguage] || '';
}
