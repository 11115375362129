import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'celum-auth-logout-component',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '',
  standalone: true
})
export class LogoutComponent {
  constructor(msal: MsalService) {
    msal.logoutRedirect({ onRedirectNavigate: () => false });
  }
}
