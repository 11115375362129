import { InjectionToken, Provider } from '@angular/core';

import { IPaginationResult } from '@celum/core';

/**
 * Function that takes in a backend response and transforms it to a unified pagination result in the form of {@see IPaginationResult}
 */
export type PaginationTranslatorFn = (data: any) => IPaginationResult;

/**
 * Injection token to define how the pagination information should be read from data sent by the backend.
 * For type safety and easier access, you can use the helper function {@see providePaginationTranslator} in the providers array of your app.
 */
export const PAGINATION_TRANSLATOR_FN = new InjectionToken<PaginationTranslatorFn>('CELUM_PAGINATION_STRATEGY');

/**
 * Helper function to provide a pagination translator in a type-safe manner.
 */
export function providePaginationTranslator(strategy: PaginationTranslatorFn): Provider {
  return {
    provide: PAGINATION_TRANSLATOR_FN,
    useValue: strategy
  };
}

/**
 * Convenience helper to create a pagination translator function by specifying deviations from a default assumed shape
 * @param key of the raw data where the pagination information can be found
 * @param propertyMap map that specifies under which property the needed information can be found
 */
export function paginationTranslatorFactory(
  key: string,
  propertyMap?: { hasBottomKey?: string; hasTopKey?: string; totalElementCountKey?: string }
): PaginationTranslatorFn {
  return ({ [key]: paging }) => {
    if (!paging) {
      return null;
    }
    return {
      hasBottom: paging[propertyMap?.hasBottomKey ?? 'hasBottom'],
      hasTop: paging[propertyMap?.hasTopKey ?? 'hasTop'],
      totalElementCount: paging[propertyMap?.totalElementCountKey ?? 'totalElementCount']
    };
  };
}
