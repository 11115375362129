import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';

import { DateUtil } from './date-util';

/**
 * Date Adapter for mat date picker. Uses default values and locale from translate service.
 */
@Injectable()
export class DefaultDateAdapter extends NativeDateAdapter {
  constructor(private translateService: TranslateService) {
    super(translateService.currentLang);

    this.translateService.onLangChange.subscribe(lang => {
      this.setLocale(lang.lang);
    });
  }

  public getFirstDayOfWeek(): number {
    return 1;
  }

  public format(date: Date, displayFormat: any): string {
    if (displayFormat === 'input') {
      return DateUtil.handleDateFormat(
        date,
        DateUtil.getFormat(this.translateService.currentLang, DateUtil.DEFAULT_FORMATS, false),
        DateUtil.DEFAULT_FORMATS,
        this.translateService.currentLang
      );
    } else {
      return DateUtil.getFormattedDate(date, DateUtil.FORMATS.DATE.NAMED_SHORT, this.translateService.currentLang);
    }
  }

  /**
   * Parses a date from a user-provided value.
   *
   * We override the parse method and delegate to date-fns because the NativeDateAdapter can't parse date in different (e.g. german) locales.
   *
   * @param value The value to parse.
   * @returns The parsed date.
   */
  public parse(value: any): Date | null {
    if (value) {
      return DateUtil.convertToDateNumeric(value, DateUtil.DEFAULT_FORMATS, this.translateService.currentLang);
    } else {
      return null;
    }
  }
}
