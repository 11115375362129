/**
 * Defines a scope within an application. This scope also has a "level" which can be used to "rank" entries. It is up to the concrete use case how this
 * level is treated (if lower value means a high or low rank)
 */

/**
 * This class holds a reference to the current context a user may be in or the context in which a component lives, for example an app.
 */
export class Context {

  /**
   * A context may include the whole application and therefore is "global"
   */
  public static GLOBAL: Context = Context.ofLevel('global', 100);

  constructor(private id: string, private level: number) {

  }

  public getId(): string {
    return this.id;
  }

  public getLevel(): number {
    return this.level;
  }

  /**
   * Creates a context with level 1: most "important" level.
   *
   * @param id Context identifier
   */
  public static of(id: string): Context {
    return new Context(id, 1);
  }

  /**
   * Creates a context with a specific level, usable for priorization of items connected by context.
   *
   * @param id Context identifier
   * @param level Used for sorting
   */
  public static ofLevel(id: string, level: number): Context {
    return new Context(id, level);
  }

  public static sortAscending(contexts: Context[]): Context[] {
    contexts.sort((c1: Context, c2: Context) => {
      if (c1.getLevel() === c2.getLevel()) {
        return 0;
      }
      return c1.getLevel() < c2.getLevel() ? -1 : 1;
    });
    return contexts; // return like so because otherwise ngc throws lambda not supported errors...
  }

  public static sortDescending(contexts: Context[]): Context[] {
    contexts.sort((c1: Context, c2: Context) => {
      if (c1.getLevel() === c2.getLevel()) {
        return 0;
      }
      return c1.getLevel() < c2.getLevel() ? 1 : -1;
    });
    return contexts; // return like so because otherwise ngc throws lambda not supported errors...
  }

}
