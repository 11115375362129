import { isDate } from '../../core/util/data-util';

interface ObjectEqualityOptions<T> {
  /** After some basic null and undefined checks, top level objects will be compared using this function */
  customEqualityCheck?: (a: T, b: T) => boolean;
  /** Set if element order should be checked in arrays inside the object */
  respectArrayOrder?: boolean;
  /** If true, undefined and not defined values will be considered as equal (by default this will not be considered as equal). */
  considerUndefinedAndNotDefinedAsEqual?: boolean;
}

type ArrayEqualityOptions = {
  /** After some basic null and undefined checks, each element of the arrays will be compared using this function */
  customEqualityCheck?: (a: any, b: any) => boolean;
  /** Set if element order should be checked in array, and nested arrays */
  respectArrayOrder?: boolean;
};

function basicCompare(obj1: any, obj2: any): boolean {
  // if objects are not equal and one of them is null, they are not equal!
  // eslint-disable-next-line eqeqeq
  if (obj1 != obj2 && (obj1 == null || obj2 == null)) {
    return false;
  }

  // both are null/undefined -> equal but no other action required!
  if (!obj1 && !obj2) {
    return true;
  }

  return null;
}


export function isEqualSimpleObject<T>(obj1: any, obj2: any, options?: ObjectEqualityOptions<T>): boolean;
/**
 * @deprecated please use {@link ObjectEqualityOptions} as third param type instead of function
 */
export function isEqualSimpleObject<T>(obj1: any, obj2: any, options?: ((a: T, b: T) => boolean)): boolean;

/**
 * Compares two SIMPLE objects.
 * Note: might not work as expected if the object contains functions or is extending a class.
 */
export function isEqualSimpleObject<T>(obj1: any, obj2: any, options?: ((a: T, b: T) => boolean) | ObjectEqualityOptions<T>): boolean {
  const equalityOptions = options && typeof options === 'object' ? options : { customEqualityCheck: options } as ObjectEqualityOptions<T>;
  const nullOrUndefined = basicCompare(obj1, obj2);

  if (nullOrUndefined !== null) {
    return nullOrUndefined;
  }

  if (equalityOptions?.customEqualityCheck) {
    return equalityOptions.customEqualityCheck(obj1, obj2);
  }

  let obj1Keys = Object.keys(obj1);
  let obj2Keys = Object.keys(obj2);

  // if undefined and not defined values should be considered as equal, filter undefined values from the objects!
  if (equalityOptions.considerUndefinedAndNotDefinedAsEqual)  {
    obj1Keys = obj1Keys.filter(key => obj1[key] !== undefined);
    obj2Keys = obj2Keys.filter(key => obj2[key] !== undefined);
  }

  // different number of properties -> not equal!
  if (obj1Keys.length !== obj2Keys.length) {
    return false;
  }

  // different properties -> not equal!
  if (!obj1Keys.every(key => obj2Keys.includes(key))) {
    return false;
  }

  let equal = true;

  for (const key of obj1Keys) {
    const value = obj1[key];
    const obj2Value = obj2[key];

    // eslint-disable-next-line eqeqeq
    if (value == null && obj2Value == null) {
      equal = true;
    } else if (value ? !obj2Value : !!obj2Value) {
      // if either one is null but not the other -> not equal!
      equal = false;
    } else if (typeof value !== typeof obj2Value) {
      equal = false;
    } else if (Array.isArray(value)) {
      equal = isArrayEqual(value, obj2Value, equalityOptions);
    } else if (isMap(value)) {
      equal = isEqualSimpleObject(Object.fromEntries(value), Object.fromEntries(obj2Value), equalityOptions);
    } else if (isDate(value) && isDate(obj2Value)) {
      equal = value.getTime() === obj2Value.getTime();
    } else if (isDate(value) || isDate(obj2Value)) {
      equal = false; // if one is date and the other not... well, they obviously not equal!
    } else if (typeof value === 'object') {
      equal = isEqualSimpleObject(value, obj2Value, equalityOptions);
    } else {
      equal = value === obj2Value;
    }

    if (!equal) {
      break;
    }
  }

  return equal;
}

function basicArrayCheck<T>(array1: T[], array2: T[]): { result: boolean } {
  if (!array1 || !array2) {
    return { result: !array1 && !array2 };
  }

  if (array1.length !== array2.length) {
    return { result: false };
  }

  if (array1.length === 0) {
    return { result: true };
  }

  return null;
}

type PrimitiveArray = boolean[] | number[] | bigint[] | string[] | symbol[];

function isPrimitiveArray<T>(value: T[] | PrimitiveArray): value is PrimitiveArray {
  return ['boolean', 'number', 'bigint', 'string', 'symbol'].includes(typeof value[0]);
}

/**
 * Compares two arrays of primitive types.
 * Implemented to handle the specific problems with performances!
 *
 * @param array1 first array
 * @param array2 second array
 *
 * @return true if arrays are equal
 */
export function isPrimitiveArrayEqual<T extends PrimitiveArray>(array1: T, array2: T): boolean {
  const basicCheck = basicArrayCheck(array1 as any, array2 as any);
  if (basicCheck) {
    return basicCheck.result;
  }

  const occurrences: Record<string, number> = {};
  for (const item of array1) {
    const itemOccurrence = occurrences[String(item)];
    if (itemOccurrence) {
      occurrences[String(item)] = itemOccurrence + 1;
    } else {
      occurrences[String(item)] = 1;
    }
  }

  for (const item of array2) {
    const itemOccurrence = occurrences[String(item)];
    if (itemOccurrence !== undefined) {
      occurrences[String(item)] = itemOccurrence - 1;
    }
  }

  return Object.values(occurrences).every(occurrence => occurrence === 0);
}

/**
 * Compares two arrays. Will not work as expected when arrays contain functions. Works as expected with either only objects or only non-objects in array.
 *
 * @deprecated please use {@link isArrayEqual} instead
 *
 * @param array1 first array
 * @param array2 second array
 * @param respectOrder whether or not the order has to be the same in both arrays
 * @param itemsSameFn optional function to compare items. If not provided, for arrays with non-object elements fallbacks to the default equality check (a ===
 *   b). If not provided, for arrays with object elements fallbacks to the {@link isEqualSimpleObject}
 *
 * @return true if arrays are equal
 */
export function isSimpleArrayEqual<T>(array1: T[], array2: T[], respectOrder = false, itemsSameFn?: (a: T, b: T) => boolean): boolean {
  return isArrayEqual(array1, array2, { respectArrayOrder: respectOrder, customEqualityCheck: itemsSameFn });
}

/**
 * Compares two arrays. Will not work as expected when arrays contain functions. Works as expected with either only objects or only non-objects in array.
 *
 * @param array1 first array
 * @param array2 second array
 * @param options optional functions to compare data of different types. If options.customEqualityCheck is not provided,
 *   for arrays with non-object elements fallbacks to the default equality check (a === b).
 *   If options.customEqualityCheck not provided, for arrays with object elements fallbacks to the {@link isEqualSimpleObject}
 *
 * @return true if arrays are equal
 */
export function isArrayEqual<T>(array1: T[], array2: T[], options?: ArrayEqualityOptions): boolean {
  const basicCheck = basicArrayCheck(array1, array2);
  if (basicCheck) {
    return basicCheck.result;
  }

  const nonObjectEntries = typeof array1[0] !== 'object';
  const compareFn = (param1: T, param2: T) => options?.customEqualityCheck ? options.customEqualityCheck(param1, param2) :
    nonObjectEntries ? param1 === param2 : isEqualSimpleObject(param1, param2, options);

  if (options?.respectArrayOrder) {
    return array1.every((value, idx) => compareFn(value, array2[idx]));
  } else if (!options?.customEqualityCheck && isPrimitiveArray(array1) && isPrimitiveArray(array2)) {
    return isPrimitiveArrayEqual(array1, array2);
  } else {
    const usedIndices = new Set<number>();
    return array1.every(value => {
      const index = array2.findIndex((value2, idx) => !usedIndices.has(idx) && compareFn(value, value2));
      if (index === -1) {
        return false;
      }

      usedIndices.add(index);
      return true;
    });
  }
}

/**
 * Compares two sets. Only works for simple data types (number, string, boolean)
 * @param set1 first set
 * @param set2 second set
 * @return true if sets are equal, false if not
 */
export function isSimpleSetEqual<T>(set1: Set<T>, set2: Set<T>): boolean {
  if (!set1 || !set2) {
    return !set1 && !set2;
  }

  if (set1.size !== set2.size) {
    return false;
  }
  return [...set1].every(item1 => set2.has(item1));
}

/**
 * Compares two sets. Only works for simple data types (number, string, boolean)
 * @param map1 first map
 * @param map2 second map
 * @return true if maps are equal, false if not
 */
export function isSimpleMapEqual<K, V>(map1: Map<K, V>, map2: Map<K, V>): boolean {
  if (!map1 || !map2) {
    return !map1 && !map2;
  }

  if (map1.size !== map2.size) {
    return false;
  }

  return [...map1.entries()].every(([key, value]) => map2.get(key) === value);
}

/**
 * Returns true if both arrays are either empty or null/undefined.
 * Returns false if only one of them is empty or null/undefined.
 */
export function arraysEmptyOrNull<T>(array1: T[], array2: T[]): boolean {
  return isEmptyArrayOrNull(array1) && isEmptyArrayOrNull(array2);
}

/**
 * Returns true if the array is empty or null/undefined.
 */
export function isEmptyArrayOrNull(array: any[]): boolean {
  return !Array.isArray(array) || array.length === 0;
}

/**
 * Checks whether values a and b are equal. Works for primitive values, arrays and objects. Does not work for String, Number or Boolean!
 * May return unexpected results of values are not of the same type.
 *
 * @param a   first value
 * @param b   second value
 * @param itemsSameFn optionally pass a comparer function
 */
export function isSimpleEqual<T>(a: T, b: T, itemsSameFn?: (a: T, b: T) => boolean): boolean {
  const nullOrUndefined = basicCompare(a, b);

  if (nullOrUndefined !== null) {
    return nullOrUndefined;
  }

  if (a === b) {
    // if both are the same
    return true;
  } else if (Array.isArray(a) && Array.isArray(b)) {
    // both are arrays
    return isArrayEqual(a, b, { respectArrayOrder: false, customEqualityCheck: itemsSameFn });
  } else if (isDate(a) && isDate(b)) {
    return a.getTime() === b.getTime();
  } else if (isDate(a) || isDate(b)) {
    // if one is date and the other not... well, they obviously not equal!
    return false;
  } else if (typeof a === 'object' && typeof b === 'object') {
    // both are objects
    return isEqualSimpleObject(a, b, { customEqualityCheck: itemsSameFn });
  } else {
    return a === b;
  }
}

function isMap(value: any): boolean {
  return value instanceof Map;
}
