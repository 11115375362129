import { InjectionToken, Type } from '@angular/core';
import { Observable } from 'rxjs';

import { GeneralSettings, PortalConfiguration, WidgetConfigMap, WidgetIdentifier } from '@celum/portals/domain';

import { WidgetConfigurationComponent } from '../components/widget-configuration.component';

export const CONFIGURATION_SERVICE = new InjectionToken<ConfigurationService>('Portal ConfigurationService');

export type WidgetConfigInfo = { widgetId: WidgetIdentifier; componentClass: Type<WidgetConfigurationComponent<any>> };

/**
 * Service that exposes the configuration of a portal.
 */
export interface ConfigurationService {
  /** General, global settings for the whole portal. */
  generalSettings$: Observable<GeneralSettings>;
  /** The whole portal configuration. */
  portalConfiguration$: Observable<PortalConfiguration>;
  /** The list of widgets for the active page. */
  widgets$: Observable<WidgetIdentifier[]>;
  /** The map of widget configurations for the active page. */
  widgetConfigs$: Observable<WidgetConfigMap>;
  /** The id of the portal. */
  portalId$: Observable<string>;

  /**
   * Convenience method to access the id of the portal statically. The value might not be there yet! To make sure that the value is already set, consider
   * subscribing to {@link portalId$} instead!
   */
  getPortalId(): string;

  /**
   * Select the widget with the given id.
   */
  selectWidget(widgetId: string): void;

  /**
   * Clear current selection.
   */
  clearSelectedWidget(): void;
}
