import { MsalService } from '@azure/msal-angular';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { AuthenticationResult } from '@azure/msal-common';
import { BaseAuthRequest } from '@azure/msal-common/dist/request/BaseAuthRequest';
import { defer, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { DataUtil } from '@celum/core';

import { LoginRedirectService } from './services/login-redirect.service';

export function tokenProviders(client: MsalService, request: BaseAuthRequest, loginRedirectService: LoginRedirectService): Observable<AuthenticationResult>[] {
  return [
    defer(() =>
      client.acquireTokenSilent(request).pipe(
        catchError(error => {
          if (error instanceof InteractionRequiredAuthError) {
            console.warn('TokenProvider: Could not acquire token silently - Re-login required');
            return of(null);
          }
          return of(null);
        })
      )
    ),
    // Handles the case where we already got a B2C token, but it is expired. This will request a new token without prompting the user to sign in
    defer(() => {
      /* Set the login redirect url in case login was triggered by an interceptor - in that case this is the only other place where we will be able to store the
        redirect url. Otherwise, the app will hang in the logged-in route */
      storeCurrentUrlAsRedirectUrlIfNeeded(loginRedirectService);

      return client.acquireTokenRedirect(request).pipe(map(() => null));
    })
  ];
}

function storeCurrentUrlAsRedirectUrlIfNeeded(loginRedirectService: LoginRedirectService): void {
  const storedRedirectUri = loginRedirectService.getLoginRedirectUri();
  const currentCleanedUrl = loginRedirectService.cleanRedirectUri(window.location.pathname + window.location.search);
  // Store the redirectUri if there is non yet, or the user e.g. clicked on a link to a subpage while they are logged out.
  // Avoid overriding a deeplink with '/' - this would happen after the initial login
  if (DataUtil.isEmpty(storedRedirectUri) || (storedRedirectUri !== currentCleanedUrl && currentCleanedUrl !== '/')) {
    loginRedirectService.storeLoginRedirectUri(currentCleanedUrl);
  }
}
