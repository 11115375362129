// eslint-disable-next-line max-classes-per-file
import { Action } from '@ngrx/store';

export interface UploadEventData {
  type: 'progress' | 'failed' | 'finished';
  ticket: string;
  progress?: number;
  error?: any;
}

export enum UploadActionTypes {
  PrepareUploads = '[UploadAction] Prepare uploads',
  StartUpload = '[UploadAction] Start upload',
  UploadEvent = '[UploadAction] Upload event',
  RemoveUpload = '[UploadAction] Remove upload',
  RemoveUploadQueue = '[UploadAction] Remove upload queue'
}

export class PrepareUploads implements Action {
  public readonly type = UploadActionTypes.PrepareUploads;

  constructor(public ticketInfo: { ticket: string, size: number }[], public queueId: string) {
  }
}

export class StartUpload implements Action {
  public readonly type = UploadActionTypes.StartUpload;

  constructor(public tickets: string[]) {
  }
}

export class UploadEvent implements Action {
  public readonly type = UploadActionTypes.UploadEvent;

  constructor(public events: UploadEventData[]) {
  }
}

export class RemoveUpload implements Action {
  public readonly type = UploadActionTypes.RemoveUpload;

  constructor(public ticket: string) {
  }
}

export class RemoveUploadQueue implements Action {
  public readonly type = UploadActionTypes.RemoveUploadQueue;

  constructor(public queueId: string) {
  }
}

export type UploadActions = PrepareUploads | StartUpload | RemoveUpload | RemoveUploadQueue | UploadEvent;
