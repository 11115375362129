import { inject, Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

// Color: Text editor will always use rgb(x,y,z) format or color: inherit, but the old color picker could have hsl and hex values
// and title widget was migrated to text widget using those formats. For alpha channel I've only seen 0.xy format, so I won't cover other formats
const ALLOWED_STYLE_REGEX = [
  /^font-weight:lighter$/,
  /^color:inherit$/,
  /^color:rgb\(\d{1,3},\d{1,3},\d{1,3}\)$/,
  /^color:rgba\(\d{1,3},\d{1,3},\d{1,3}(,\d\.?\d*)?\)$/,
  /^color:#[0-9a-f]{1,6}$/,
  /^color:hsl\(\d{1,3}\.?\d*,\d{1,3}\.?\d*%,\d{1,3}\.?\d*%\)/,
  /^color:hsla\(\d{1,3}\.?\d*,\d{1,3}\.?\d*%,\d{1,3}\.?\d*%(,\d\.?\d*)?\)$/,
  /^font-size:[0-9]{1,3}px$/
];

@Pipe({
        standalone: true,
        name: 'sanitizeForQuill',
        pure: true
      })
export class SanitizeForQuillPipe implements PipeTransform {
  private safeDataList: { [key: string]: 'ordered' | 'bullet' };
  private safeStyleList: { [key: string]: string };
  private sanitizer = inject(DomSanitizer);
  private idCounter = 0;

  public transform(value: any): SafeHtml {
    if (!value) {
      return '';
    }

    this.safeStyleList = {};
    this.safeDataList = {};
    this.idCounter = 0;
    const htmlString = `<div>${value}</div>`;
    const parser = new DOMParser();
    const div = parser.parseFromString(htmlString, 'text/html').body.firstChild as HTMLElement;
    // extract safe style and data-list attributes from the given html string
    this.collectSafeStyleAndDataListAttrs(div);

    // sanitize the html string
    const sanitized = this.sanitizer.sanitize(SecurityContext.HTML, div.innerHTML);
    div.innerHTML = sanitized;

    // insert the collected style and data-list attributes back to the sanitized string
    this.insertSafeStyleAndDataListAttrs(div);
    return this.sanitizer.bypassSecurityTrustHtml(div.innerHTML);
  }

  /**
   * Collects all secure style and data-list attributes from the given element and its children and adds label attribute, which is used to identify the element
   * later
   */
  private collectSafeStyleAndDataListAttrs(element: HTMLElement): void {
    const dataList = element.attributes.getNamedItem('data-list')?.value;
    const style = element.attributes.getNamedItem('style')?.value;

    if (dataList === 'ordered' || dataList === 'bullet') {
      this.safeDataList[`id-${this.idCounter}`] = dataList;
    }

    if (style) {
      const sanitizedStyle = this.sanitizeStyle(style);
      if (sanitizedStyle.length > 0) {
        this.safeStyleList[`id-${this.idCounter}`] = sanitizedStyle;
      }
    }

    element.setAttribute('label', `id-${this.idCounter}`);
    this.idCounter++;
    for (let i = 0; i < element.children.length; i++) {
      this.collectSafeStyleAndDataListAttrs(element.children[i] as HTMLElement);
    }
  }

  /**
   * Based on the label attribute, inserts the collected style and data-list attributes to the element and its children
   */
  private insertSafeStyleAndDataListAttrs(element: HTMLElement): void {
    const label = element.getAttribute('label');
    if (this.safeDataList[label]) {
      element.setAttribute('data-list', this.safeDataList[label]);
    }
    if (this.safeStyleList[label]) {
      element.setAttribute('style', this.safeStyleList[label]);
    }
    element.removeAttribute('label');
    for (let i = 0; i < element.children.length; i++) {
      this.insertSafeStyleAndDataListAttrs(element.children[i] as HTMLElement);
    }
  }

  private sanitizeStyle(stylesString: string): string {
    const styleArray = stylesString
      .replace(/\s/g, '')
      .split(';')
      .filter(style => ALLOWED_STYLE_REGEX.some(regExp => regExp.test(style)));
    return styleArray.length > 0 ? styleArray.join(';') + ';' : '';
  }
}
