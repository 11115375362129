import { TranslateService } from '@ngx-translate/core';
import { concat, EMPTY, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Returns a stream of the current ui - language. If it emits, it's guaranteed that the language files for the new language are already loaded
 */
export function currentLocaleStream(translate: TranslateService): Observable<string> {
  const currentLang = translate.currentLang;
  const translationAlreadyLoaded = translate.translations[currentLang];

  const waitTillLoaded$ = translationAlreadyLoaded ? of(true) : EMPTY;
  return concat(
    // get latest language
    waitTillLoaded$.pipe(map(() => translate.currentLang)),
    translate.onLangChange.pipe(map(event => event.lang))
  );
}
