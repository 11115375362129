import { NgModule } from '@angular/core';

import { LanguagePipe } from './language.pipe';

/**
 * Exposes the LanguagePipe.
 */
@NgModule({
            imports: [],
            declarations: [LanguagePipe],
            providers: [],
            exports: [LanguagePipe]
          })
export class TranslationModule {
}
