export function formatNumber(input: number | string, locale: string): string {
  const numberValue = typeof input === 'string' ? parseFloat(input) : input;
  if (Number.isNaN(numberValue)) {
    return '';
  }
  const [prefix, power] = numberValue.toString(10).split('e');
  const decimals = numberValue.toString(10).split('.')[1]?.split('e')[0];
  return parseFloat(prefix).toLocaleString(locale === 'en' ? 'en-GB' : locale, {
    minimumFractionDigits: decimals?.length ?? 0
  }) + (power ? 'e' + power : '');
}

export function formatCount(count: number): string {
  if ((!count && count !== 0) || count < 0) {
    return null;
  }

  if (count < 1000) {
    return count + '';
  }

  if (count >= 1000 && count < 100000) {
    return (Math.floor((count / 1000) * 10) / 10).toFixed(1) + 'k';
  }

  if (count >= 100000 && count < 1000000) {
    return Math.floor(count / 1000) + 'k';
  }

  return (Math.floor((count / 1000000) * 10) / 10).toFixed(1) + 'M';
}
