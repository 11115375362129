/**
 * Group all elements of an array by a specific condition provided with the groupByFn parameter.
 * @param array that should be grouped
 * @param groupByFn function that determines the key of a given item
 * @return Map with the key as key and all values that had this key as value
 */
export function groupArrayBy<T, E>(array: T[], groupByFn: (a: T) => E): Map<E, T[]> {
  if (!array || !groupByFn) {
    return null;
  }

  const map = new Map<E, T[]>();
  array.forEach(item => {
    const key = groupByFn(item);
    if (!map.has(key)) {
      map.set(key, [item]);
    } else {
      map.get(key).push(item);
    }
  });
  return map;
}

/**
 * Merge two arrays together into one array
 * @param arr1 first array
 * @param arr2 second array
 * @param overwriteDuplicates whether or not elements from arr2 should overwrite equal (===) values from arr1 (true) or if they should be added again (false)
 * @param itemIdentifier custom identifier to enable duplicate detection with complex types (e.g. property on an object)
 */
export function mergeArrays<T>(arr1: T[], arr2: T[], overwriteDuplicates = true, itemIdentifier?: (item: T) => any): T[] {
  if (!overwriteDuplicates) {
    return [...arr1, ...arr2];
  }

  // O(n) solution - aka no nested for loops

  // collect all identifiers from arr1 into a map with their respective index
  const arr1Identifiers = new Map<string, number>();
  arr1.forEach((item, idx) => {
    const identifier = itemIdentifier?.(item) ?? item;
    arr1Identifiers.set(identifier, idx);
  });

  const merged = [...arr1];
  arr2.forEach(item => {
    // check if the element was already in arr1 and overwrite/push accordingly
    const identifier = itemIdentifier?.(item) ?? item;
    if (arr1Identifiers.has(identifier)) {
      merged[arr1Identifiers.get(identifier)] = item;
    } else {
      merged.push(item);
    }
  });

  return merged;
}

/**
 * Get all values that are present in {@param arr2}, but not in {@param arr1}
 */
export function getAddedItems<T>(arr1: T[], arr2: T[]): T[] {
  if (!arr1 || !arr2) {
    return arr2;
  }

  const set1 = new Set(arr1);
  return arr2.filter(val2 => !set1.has(val2));
}
