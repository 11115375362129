import { Context } from '../../../core/common/context';
import { AnyEntityType, EntityType } from '../../../core/model/entity-type';
import { UUIDGenerator } from '../../../core/util/uuid-generator';
import { Customization } from '../../../extensions/customization/customization';
import { Scope } from '../../../extensions/scope';
import { DataContext } from '../context/data-context';
import { CustomizerScope } from '../model/customizer-scope';

/**
 * Extend this class to add a specific customizing for filtering the available operations based on the defined values.
 */
export abstract class OperationFilterCustomization implements Customization {

  public static type = 'OperationFilterCustomization';

  public getScope(): Scope {
    return CustomizerScope.forOperations(this.appliesToEntityTypes(), this.appliesToContexts(), this.appliesToOperations());
  }

  /**
   * Define a unique identifier for this customization.
   */
  public getIdentifier(): string {
    return UUIDGenerator.generateId();
  }

  public getTypeIdentifier(): string {
    return OperationFilterCustomization.type;
  }

  /**
   * Define the contexts this filter should be applied to.
   */
  public appliesToContexts(): Context[] {
    return [Context.GLOBAL];
  }

  /**
   * Define the EntityTypes this filter should be applied to.
   */
  public appliesToEntityTypes(): EntityType[] {
    return [new AnyEntityType()];
  }

  /**
   * Define the keys of the operations this filter should be applied to.
   */
  public abstract appliesToOperations(): string[];

  /**
   * Called to check whether this filter actually should be applied.
   * @param context the current DataContext used for checking
   */
  public abstract isAvailable(context: DataContext): boolean;
}
