import { Injectable } from '@angular/core';

import { PortalsProperties } from '@celum/portals/domain';
import { ErrorService } from '@celum/shared/util';

@Injectable({ providedIn: 'root' })
export class CustomDomainCheckService {
  constructor(private errorService: ErrorService) {}

  /**
   * Used to check if the provided domain is still connected to the Celum domain.
   * @param domain The domain to check. If the domain is localhost, the check is skipped with a default value of true.
   * @param portalIdOrSlug The portal id or slug to check the domain against.
   */
  public cNameCheck(domain: string, portalIdOrSlug: string): Promise<boolean> {
    if (domain.startsWith('localhost:')) {
      return Promise.resolve(true);
    }

    return fetch(`${PortalsProperties.properties.apiUrl}/custom-domains/cname-validation?name=${domain}&portalIdOrSlug=${portalIdOrSlug}`)
      .then(() => true)
      .catch(error => {
        this.errorService.error('CustomDomainService', 'CNAME check failed, should not redirect to custom domain', error);
        return false;
      });
  }
}
