import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, Injector, Optional } from '@angular/core';
import { Store } from '@ngrx/store';

import { ShowSnackbar, SimpleSnackbar, SnackbarConfiguration } from '@celum/common-components';
import { TranslationHelper } from '@celum/ng2base';
import { TRACKING_SERVICE, TrackingService } from '@celum/shared/domain';

@Injectable({ providedIn: 'root' })
export class ErrorService {
  constructor(
    private translationService: TranslationHelper,
    private injector: Injector,
    @Optional() @Inject(TRACKING_SERVICE) private trackingService: TrackingService
  ) {}

  /**
   * Handles generic http errors.
   * @param httpError The http error object
   * @param component The component where the error occurred
   */
  public httpError(httpError: HttpErrorResponse, component?: string): void {
    const status = httpError?.status;
    // Map specific error codes to a "something went wrong" message
    if (status === 408 || (status >= 500 && status < 600)) {
      return this.error(component, 'ERROR.GENERAL', httpError.error);
    }

    return this.error(component, httpError?.error?.errorKey || '', httpError, undefined, true);
  }

  /**
   * Prints error message to console and shows snackbar.
   *
   * @param component the component where error occurred
   * @param messageOrKey the error message or messageKey
   * @param error the error object
   * @param autoVanish true if the snackbar should disappear automatically
   * @param fallbackToUnexpected true if the error should be handled as unexpected error if no translation is found
   */
  public error(component?: string, messageOrKey?: string, error?: any, autoVanish?: boolean, fallbackToUnexpected?: boolean): void {
    const componentMessage = component ? `${component}: ` : '';
    const snackbarId = component ?? 'error';

    const translatedMessage = messageOrKey ? this.translationService.instant(messageOrKey) : '';
    if (fallbackToUnexpected && messageOrKey === translatedMessage) {
      this.unexpected(null, error, component);
      return;
    }

    console.error(`${componentMessage}${translatedMessage}`, error);
    this.trackingService?.trackError(error);
    // Inject the store dynamically. Injecting it into the constructor causes a cyclic dependency if ngrx storedevtoolsmodule is used
    const store = this.injector.get(Store);
    store.next(new ShowSnackbar(snackbarId, SimpleSnackbar, SnackbarConfiguration.error(messageOrKey, { autoVanish })));
  }

  /**
   * Prints message to console and shows snackbar for unexpected errors.
   *
   * @param messageOrKey the message or messageKey for unexpected errors
   * @param error the error object
   * @param component the component where error occurred
   */
  public unexpected(messageOrKey?: string, error?: any, component?: string): void {
    const componentMessage = component ? `${component}: ` : '';
    const messageOrKeyWithDefault = messageOrKey ? messageOrKey : 'ERROR.UNEXPECTED';
    const translatedMessage = this.translationService.instant(messageOrKeyWithDefault);
    console.error(`${componentMessage}Unexpected error: ${translatedMessage}`, error);
    this.trackingService?.trackError(error);

    if (!messageOrKey?.includes('Error retrieving icon')) {
      // todo workaround around error handling is implement in ticket https://celum.atlassian.net/browse/SPOR-98 / https://celum.atlassian.net/browse/SPOR-99 as I can't come up
      // with a good solution for now ¯\_(ツ)_/¯
      // Inject the store dynamically. Injecting it into the constructor causes a cyclic dependency if ngrx storedevtoolsmodule is used
      const store = this.injector.get(Store);
      store.next(new ShowSnackbar('unexpected', SimpleSnackbar, SnackbarConfiguration.error(translatedMessage)));
    }
  }
}
