import { createFeatureSelector, createSelector, createSelectorFactory, resultMemoize } from '@ngrx/store';

import { isArrayEqual } from '@celum/core';

import { ItemListProgress, ItemProgress, TicketProgressStates } from '../../model/progress-state';

interface QueueInformation {
  progress: ItemProgress;
  ticketStates: TicketProgressStates;
  tickets: Set<string>;
}

export interface UploadState {
  tickets: {
    [key: string]: {
      queueId: string,
      progress: ItemProgress
    }
  };

  queues: {
    [key: string]: QueueInformation
  };
}

export const initialUploadState: UploadState = {
  queues: {},
  tickets: {}
};

const uploadState = createFeatureSelector<UploadState>('upload');

export const getTicketProgress = (ticket: string) => createSelector<object, [UploadState], ItemProgress>(
  uploadState,
  state => state.tickets[ticket] && state.tickets[ticket].progress
);

const simpleArrayMemoize = (fn: () => any) => resultMemoize(fn, isArrayEqual);

export const getTicketProgressForQueue = (queueId: string) => createSelectorFactory<UploadState, ItemProgress[]>(simpleArrayMemoize)(
  uploadState,
  (state: UploadState) => getTicketsForQueue(state, queueId)
);

const getQueueProgress = (queueId: string) => createSelector<object, [UploadState], any>(
  uploadState,
  state => state.queues[queueId]
);

export const getQueueProgressReport = (queueId: string) => createSelector<UploadState, [QueueInformation, ItemProgress[]], ItemListProgress>(
  getQueueProgress(queueId),
  getTicketProgressForQueue(queueId),
  (queue, tickets) => convertQueueProgress(queue, tickets)
);

export const getQueueProgressReports = createSelector(
  uploadState,
  (state: UploadState) => Object.entries(state.queues).map(([queueId, queue]) => convertQueueProgress(queue, getTicketsForQueue(state, queueId)))
);

function getTicketsForQueue(state: UploadState, queueId: string): ItemProgress[] {
  const tickets = state.queues[queueId] && state.queues[queueId].tickets || new Set();
  return Array.from(tickets).map(ticket => state.tickets[ticket].progress);
}

function convertQueueProgress(queue: QueueInformation, tickets: ItemProgress[]): ItemListProgress {
  return queue && {
    ...queue.progress,
    ticketProgressStates: queue.ticketStates,
    items: tickets
  } as ItemListProgress;
}
